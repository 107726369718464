import React from "react";
import { NavLink } from "react-router-dom";
import { LinkButtonI } from "../ComponentInterface";

const LinkButton = ({
	url,
	text,
	onClick,
	className = "",
	dataTestId,
}: LinkButtonI) => {
	return onClick ? (
		<button className={`link-btn ${className}`} data-testid={dataTestId}>
			<span onClick={onClick}>{text}</span>
		</button>
	) : (
		<NavLink
			className={`link-btn ${className}`}
			to={url || "/"}
			data-testid={dataTestId}
		>
			<span>{text}</span>
		</NavLink>
	);
};

export default LinkButton;
