import { ClaimPermission, CreatePermission, PermissionRedirect } from '../../types/permission'
import { TransactionResponse } from '../../types/transaction'
import { makeAuthenticatedApiCall } from '../../utils/ApiHelper'

const URL_TX = '/tx'

//  /tx/{txId}/user
export const getTxUserById = (tx_id: string) => makeAuthenticatedApiCall<TransactionResponse>('GET', `${URL_TX}/${tx_id}/user`)

//   /tx/{txId}/permissions
export const getTxPermissionsById = async (tx_id: string, permissions: Array<CreatePermission>) =>
  makeAuthenticatedApiCall<ClaimPermission>('POST', `${URL_TX}/${tx_id}/permissions`, {
    data: permissions,
  })

// /tx/{txId}/redirect
export const getTxRedirectById = (tx_id: string, permission_code: string) =>
  makeAuthenticatedApiCall<PermissionRedirect>('GET', `${URL_TX}/${tx_id}/redirect?permission_code=${permission_code}`)

//   /tx/{txId}/cancel
// ? Used when consent-request expires or user declines I think??
export const getTxCancelById = (tx_id: string) => makeAuthenticatedApiCall<never>('GET', `${URL_TX}/${tx_id}/cancel`)
// Returns Authserver redirectUrl
