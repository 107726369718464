import React, { useEffect, useState } from "react";
import { Button, VerifCodeInput, Text } from "..";
import i18n from "../../i18n";
import { InputI, InputValidateI, errorI, PageI } from "../ComponentInterface";
import parse from "html-react-parser";

const AccessPeiForm = ({ successFunc }: PageI) => {
	const [inputs, setInputs] = useState<InputI>({ verifCode: "" });
	const [validInputs, setValidateInputs] = useState<InputValidateI>({
		isValidBtn: false,
	});
	const [errorObj, setErrorObj] = useState<errorI>({ error: "" });

	useEffect(() => {
		setValidateInputs((prev) => ({
			...prev,
			isValidBtn: Boolean(prev.isValidVerifCode),
		}));
	}, [validInputs.isValidVerifCode]);

	useEffect(() => {
		setErrorObj((prev) => ({
			...prev,
			errorNotification: prev.error === "error-500-1",
			errorVerifCode: prev.error === "error-verif-code",
		}));
	}, [errorObj.error]);

	// Check the Enter Button Press
	const keyDownHandler = (event: any) => {
		if (event.key === "Enter") {
			event.preventDefault();

			if (validInputs.isValidVerifCode) {
				submitClick();
			}
		}
	};

	const submitClick = async () => {
		// TO DO: update routing upon further development of this feature
		const { verifCode } = inputs;
		console.log(verifCode);
		successFunc && successFunc();
	};

	const title = i18n.t("recent-access");
	const description = i18n.t("enter-code");

	return (
		<>
			<div className="access-form">
				<>
					<h5>{title}</h5>
					<p data-testid="body">{description}</p>
				</>
				<form
					onKeyDown={keyDownHandler}
					onSubmit={(e) => e.preventDefault()}
					className="verif-code-form"
				>
					<div className="verif-code">
						<VerifCodeInput
							label={i18n.t("verification-code")}
							onChange={(e) => {
								setInputs((prev) => ({
									...prev,
									verifCode: e.target.value,
								}));
							}}
							dataTestId="verification-code"
						/>
						{errorObj.errorVerifCode && (
							<Text className="error">
								{parse(i18n.t(errorObj.error))}
							</Text>
						)}
						<Button
							onClick={submitClick}
							text={i18n.t("continue")}
							disabled={!validInputs.isValidBtn}
							// isLoading={isLoading}
							dataTestId="Button-primary-in-person"
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default AccessPeiForm;
