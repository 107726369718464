import React from 'react'
import { Title, Layout, Text, Button } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';
import { ReactComponent as VerifyIcon } from '../../asset/icon_verify.svg';
import { PageI } from '../../components/ComponentInterface';

const VerifySuccessPage = ({ successFunc }: PageI) => {
  return (
    <Layout header footer title={i18n.t('verify-success-title')} className='verification-success-page' logout >
      <div className='verfiy-icon-container'>
        <VerifyIcon />
      </div>

      <Title title={i18n.t('verify-success-title')} />
      <Text className="description1">{parse(i18n.t('verify-success-description-1'))}</Text>
      <Text>{parse(i18n.t('verify-success-description-2'))}</Text>

      <div className="button-container-2">
        <Button text={i18n.t('next')} onClick={successFunc} />
      </div>
    </Layout>
  )
}

export default VerifySuccessPage;