import React from 'react'
import {
  Layout,
  TermsOfServiceForm
} from "../../components";
import i18n from '../../i18n';
import { PageI } from '../../components/ComponentInterface';

const TermsOfServicePage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout header title={i18n.t('terms-of-service-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className='terms-of-service' >
      <TermsOfServiceForm successFunc={successFunc} />
    </Layout>
  )
}

export default TermsOfServicePage;