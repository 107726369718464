import React from "react";
import { Layout, SignInForm, AccessPeiForm, Title } from "../../components";
import i18n from "../../i18n";
import { PageI } from "../../components/ComponentInterface";

const SignInPage = ({ successFunc, otherRoutes: { createAccount } }: PageI) => {
	return (
		<Layout header footer title={i18n.t("sign-in-title")}>
			<Title title={i18n.t("sign-in-title")} />
			<SignInForm successFunc={successFunc} otherRoutes={createAccount} />
			{/* TO DO: add successFunc / route needed for Access PEI Form */}
			<AccessPeiForm />
		</Layout>
	);
};

export default SignInPage;
