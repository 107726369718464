import React, { FC } from 'react'
import parse from 'html-react-parser'

import { Layout, Title, Text, ListItem } from '../../../../components'
import i18n from '../../../../i18n'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../routes/common/constants'

const WalletProfilePage: FC = () => {
  const history = useHistory()

  return (
    <Layout header footer logout backText={i18n.t('back')} backBtnCallback>
      <div className="wallet-app">
        <Title title={i18n.t('wallet-profile-title')} />
        <div className="profile-information-session">
          <Text className="subtitle" dataTestId="lead-text">
            {parse(i18n.t('wallet-profile-personal-information-title'))}
          </Text>
          <div>
            <ListItem title={i18n.t('wallet-profile-personal-information-name')} value={'Sally Woods'} action={'Add'} onClick={() => {}} />
            <ListItem title={i18n.t('wallet-profile-personal-information-preferred')} value={'Sally Woods'} action={'Edit'} onClick={() => {}} />
            <ListItem title={i18n.t('wallet-profile-personal-information-birth')} value={'Sally Woods'} action={'Edit'} onClick={() => {}} />
            <ListItem title={i18n.t('wallet-profile-personal-information-address')} value={'Sally Woods'} action={'Edit'} onClick={() => {}} />
            <ListItem title={i18n.t('wallet-profile-personal-information-phone')} value={'Sally Woods'} action={'Edit'} onClick={() => {}} />
            <ListItem title={i18n.t('wallet-profile-personal-information-id')} value={'Sally Woods'} action={'Edit'} last onClick={() => {}} />
          </div>
          <div style={{ marginTop: '50px', color: 'blue', cursor: 'pointer' }} onClick={() => history.push(ROUTES.WalletConsentHistory)}>
            Account History
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WalletProfilePage
