import React, { useState } from "react";
import {
	Button,
	IconListItem,
	Layout,
	Text,
	Title,
	LinkButton,
	Modal,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { ReactComponent as Step1 } from "../../asset/authenticator-step-1-icon.svg";
import { ReactComponent as LearnMoreIcon } from "../../asset/icon_question.svg";
import { ReactComponent as ShowMeIcon } from "../../asset/icon-show-me.svg";
import { ReactComponent as CheckIcon } from "../../asset/icon-check-green.svg";
import { PageI } from "../../components/ComponentInterface";

const SetupAuthenticatorAppPage = ({ successFunc, goBackLink }: PageI) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const toggleModal = () => setModalOpen(!isModalOpen);

	return (
		<Layout
			header
			footer
			title={i18n.t("setup-authenticator-app-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="authenticator-app-flow progress-bar"
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={4}
		>
			<Title title={i18n.t("setup-authenticator-app-title")} />
			<IconListItem logo={<Step1 />} dataTestId="step-1">
				<Text>{parse(i18n.t("setup-authenticator-app-step-1"))}</Text>
			</IconListItem>

			<div className="links-container">
				<IconListItem logo={<LearnMoreIcon />} dataTestId="learn-more">
					<LinkButton
						text={i18n.t("setup-authenticator-app-learn-more")}
						onClick={toggleModal}
						dataTestId="Learn-more"
					/>
				</IconListItem>
				<IconListItem logo={<ShowMeIcon />} dataTestId="show-how">
					{/* TODO UPDATE THE REDIRECT URL TO SHOW PAGE */}
					<LinkButton
						text={i18n.t("setup-authenticator-app-show-me-link")}
						onClick={() => {}}
						dataTestId="Show-how"
					/>
				</IconListItem>
			</div>

			<div className="button-container-2">
				<Button
					className="inverted"
					text={i18n.t("setup-authenticator-app-back")}
					onClick={goBackLink}
					dataTestId="Button-secondary"
				/>
				<Button
					text={i18n.t("continue")}
					onClick={successFunc}
					dataTestId="Button-primary"
				/>
			</div>

			{/* Learn More Modal */}

			<Modal
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
				className="authenticator-modal"
			>
				<h2>{`${i18n.t(
					"setup-authenticator-app-learn-more-modal-title"
				)}`}</h2>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(
							i18n.t(
								"setup-authenticator-app-learn-more-modal-desc-1"
							)
						)}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(
							i18n.t(
								"setup-authenticator-app-learn-more-modal-desc-2"
							)
						)}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(
							i18n.t(
								"setup-authenticator-app-learn-more-modal-desc-3"
							)
						)}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(
							i18n.t(
								"setup-authenticator-app-learn-more-modal-desc-4"
							)
						)}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(
							i18n.t(
								"setup-authenticator-app-learn-more-modal-desc-5"
							)
						)}
					</Text>
				</IconListItem>
			</Modal>
		</Layout>
	);
};

export default SetupAuthenticatorAppPage;
