import React from "react";
import i18n from "../../i18n";
import IconButton from "../buttons/IconButton";
import { ReactComponent as Info } from "../../asset/icon-question.svg";
import { validateNumberInput } from "../validate";
import { NumberInputI } from "../ComponentInterface";

// TO DO: completely change this page to validate the verification code once that expected result has been created. Currently this is based off of the NumberInput component

const VerifCodeInput = ({
	value,
	onChange,
	maxLength,
	optional,
	label,
	infoClick,
	type,
	onValidate,
	dataTestId,
}: NumberInputI) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(e);
		if (onValidate)
			onValidate(validateNumberInput(e || "", maxLength || 150));
	};

	return (
		<div className="textbox-form">
			<label htmlFor={label}>
				{label} {optional && <span>{`${i18n.t("optional")}`}</span>}
			</label>
			<div
				className={`textbox-form-input ${
					infoClick ? "textbox-form-input-info" : ""
				}`}
			>
				<input
					onChange={onInputChange}
					type="text"
					min="0"
					pattern="[0-9]*"
					value={value}
					maxLength={maxLength}
					id={label}
					formNoValidate
					data-testid={dataTestId}
				/>
				{infoClick && (
					<IconButton
						onClick={infoClick}
						icon={<Info />}
						type={type}
					/>
				)}
			</div>
		</div>
	);
};

export default VerifCodeInput;
