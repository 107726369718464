import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import parse from 'html-react-parser'

import { Button, Layout, Spinner, Table, TableCard, Title } from '../../../../components'
import i18n from '../../../../i18n'
import ROUTES from '../../../../routes/common/constants'
import KeyDefinitionModal from './components/KeyDefinitionModal'

import iconQuestion from '../../../../asset/icon-table-question.svg'
import useConsentHistoryDetails from '../../hooks/useConsentHistoryDetails'
import useIsMobile from '../../../../common/hooks/useIsMobile'
import { Card } from '../../../../components/TableCard'
import RevokeModal from './components/RevokeModal'

type ConsentUrlParams = {
  client_id: string
  client_name: string
  type: string
}

interface DataTable {
  info: string
  source?: string
  expires: string
  authorized?: string
  disabled?: boolean
}

const WalletConsentHistoryDetailPage = () => {
  const { client_id, client_name, type } = useParams<ConsentUrlParams>()
  const [keyDefinitionModalOpen, setKeyDefinitionModalOpen] = useState(false)
  const [revokeModalOpen, setRevokeModalOpen] = useState(false)
  const [revokeDisabled, setRevokeDisabled] = useState(true)
  const [dataTable, setDataTable] = useState<any>([])
  const history = useHistory()
  const isMobile = useIsMobile()

  const { isLoading, isService, permissions, handleRevoke, org, isRevokeLoading } = useConsentHistoryDetails(type, client_id)

  const columns = [
    { label: i18n.t('wallet-consent-history-table-detail-info'), key: 'info' },
    { label: isService ? i18n.t('wallet-consent-history-table-detail-source') : i18n.t('wallet-consent-history-table-detail-autorized'), key: isService ? 'source' : 'authorized' },
    { label: i18n.t('wallet-consent-history-table-detail-expires'), key: 'expires' },
  ]

  useEffect(() => {
    if (isService) {
      const data = permissions.map((permission) => {
        const {
          resource: { name },
          expires,
          data_source,
          disabled,
        } = permission

        if (disabled) setRevokeDisabled(true)
        else setRevokeDisabled(false)

        return { info: name, source: data_source?.name, expires: new Date(expires).toISOString().split('T')[0], disabled: disabled || false }
      })
      const dataParsed = data.filter((item, index, self) => self.findIndex((t) => t['info'] === item['info']) === index)
      setDataTable(dataParsed)
    } else if (!isService) {
      const organization = org.filter((e) => e.name === client_name.toString())

      const data = organization.map((org) => {
        const { accounts } = org

        const dataTable = accounts.map((account) => {
          const {
            data_source: { name },
            rot: { expires_at },
            connected_on,
          } = account

          return { info: name, authorized: new Date(connected_on).toISOString().split('T')[0], expires: new Date(expires_at).toISOString().split('T')[0] }
        })

        return dataTable.length && dataTable[0]
      })
      setDataTable(data)
    }
  }, [permissions, org, client_name, isService])

  const onClickOrg = () => {}

  return (
    <>
      {isLoading ? (
        <div className="wallet-layout">
          <div className="loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <Layout header footer logout backText={i18n.t('back')} backBtnCallback={() => history.push(ROUTES.WalletConsentHistory)}>
          <div className="wallet-consent-history-details">
            <Title title={client_name} dataTestid={'h1'} />
            <div className="service-container">
              <div className="subtitle" data-testid={'label'}>
                {type}
              </div>
              <img src={iconQuestion} alt={'questions'} data-testid={'bi:question'} onClick={() => setKeyDefinitionModalOpen(true)} />
            </div>
            <div className="wallet-consent-history-act">
              <div data-testid={'text'}>
                {isService
                  ? parse(i18n.t('wallet-consent-history-details-desc-service', { service: client_name }))
                  : parse(i18n.t('wallet-consent-history-details-desc-source', { source: client_name }))}
              </div>
              {isService && (
                <div className="btn-revoke">
                  <Button
                    className={'decline'}
                    disabled={revokeDisabled}
                    onClick={() => setRevokeModalOpen(true)}
                    text={i18n.t('wallet-consent-history-btn-revoke')}
                    dataTestId={'Button-primary'}
                  />
                </div>
              )}
            </div>
            {!isMobile && (
              <div className="details-table">
                <Table columns={columns} data={dataTable} onClickRow={onClickOrg} />
              </div>
            )}
            {isMobile && (
              <div className="table-card">
                <TableCard>
                  {dataTable.map((row: DataTable, index: any) => {
                    const { expires, info, source, authorized, disabled } = row
                    return (
                      <Card
                        id={index}
                        onClickRow={() => onClickOrg()}
                        title={info}
                        disabled={disabled}
                        type={
                          isService
                            ? i18n.t('wallet-consent-history-table-card-source', { source: source })
                            : i18n.t('wallet-consent-history-table-detail-autorized-responsive', { date: authorized })
                        }
                        lastActive={i18n.t('wallet-consent-history-table-card-expires', { date: expires })}
                      />
                    )
                  })}
                </TableCard>
              </div>
            )}
          </div>
          <RevokeModal
            handleCancel={() => setRevokeModalOpen(false)}
            handleRevoke={() =>
              handleRevoke(() => {
                setRevokeModalOpen(false)
                setRevokeDisabled(true)
              })
            }
            isRevokeLoading={isRevokeLoading}
            service={client_name}
            onClose={() => setRevokeModalOpen(false)}
            isOpen={revokeModalOpen}
          />
          <KeyDefinitionModal onClose={() => setKeyDefinitionModalOpen(false)} isOpen={keyDefinitionModalOpen} />
        </Layout>
      )}
    </>
  )
}

export default WalletConsentHistoryDetailPage
