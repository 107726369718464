import React from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'

import { Modal } from '../../../../../components'

interface Props {
  onClose: () => void
  isOpen: boolean
}

const KeyDefinitionModal = ({ onClose, isOpen }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'key-definition-modal'}>
        <div className="title" data-testid={'key-title'}>
          {parse(i18n.t('wallet-consent-history-key-def-title'))}
        </div>
        <div className="subtitle" data-testid={'key-subtitle'}>
          {parse(i18n.t('wallet-consent-history-key-def-subtitle'))}
        </div>
        <div className="subtitle" data-testid={'key-who'}>
          {parse(i18n.t('wallet-consent-history-key-def-who'))}
        </div>
        <div className="subtitle" data-testid={'key-type'}>
          {parse(i18n.t('wallet-consent-history-key-def-type'))}
        </div>
        <div className="subtitle" data-testid={'key-last'}>
          {parse(i18n.t('wallet-consent-history-key-def-last'))}
        </div>
        <div className="subtitle" data-testid={'key-service'}>
          {parse(i18n.t('wallet-consent-history-key-def-service'))}
        </div>
        <div className="subtitle" data-testid={'key-source'}>
          {parse(i18n.t('wallet-consent-history-key-def-source'))}
        </div>
      </div>
    </Modal>
  )
}

export default KeyDefinitionModal
