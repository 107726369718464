import React, { useEffect, useState } from 'react'
import { Title, Layout, Text, DriverForm, Notification } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';

import { PageI, errorI } from '../../components/ComponentInterface';
import { useContext, updateProfileState } from '../../Context';
import { getSessionStorage } from "../../utils";

const VerifyDriverLicenceCardPage = ({ goBackLink, successFunc }: PageI) => {
  const { state: { userInfo }, dispatch } = useContext();
  const [errorObj, setErrorObj] = useState<errorI>({ error: '', errorNotification: false });

  const error500 = () => (setErrorObj({
    ...errorObj,
    error: 'error-500-1',
    errorNotification: true
  }));

  useEffect(() => { 
    // Profile Api call
    const getProfile = async () => {
      try {  

        // If Profile not available then get profile 
        if ((!userInfo || !userInfo?.profile || (Object.keys(userInfo?.profile).length === 0)) && !errorObj.error) {

          // SessionStorage
          const data: any = getSessionStorage('verification-profile');
          if (data) dispatch(updateProfileState(JSON.parse(data)));
        }
      } catch (e) {
        console.log('e', e);
        error500();
      }
    };
    
    getProfile();

    // eslint-disable-next-line
  }, [userInfo, errorObj]);

  return (
    <Layout header footer title={i18n.t('verify-driver-licence-card-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} logout >
      <Title title={i18n.t('verify-driver-licence-card-title')} />
      <Text>{i18n.t('verify-driver-licence-card-description')}</Text>

      <div className='error-container'>
        {errorObj.errorNotification && <Notification text={parse(i18n.t(errorObj.error))} className='error' />}
      </div>

      <DriverForm 
        successFunc={successFunc}
        readOnlyDob={true}
        readOnlyFirstName={true}
        readOnlyLastName={true}
      />
    </Layout>
  )
}

export default VerifyDriverLicenceCardPage;