import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  AccountReadyPage,
  AddSMSAuthPage,
  AddEmailAuthPage,
  Choose2FAPage,
  CreateIdEmailPage,
  ForgotPasswordCheckEmailPage,
  ForgotPasswordPage,
  OTPage,
  ResetPasswordPage,
  ResetPasswordSuccessPage,
  SignInPage,
  SMSOTPPage,
  TermsOfServicePage,
  VerifyIdPage,
  VerifyPeiIdSetupPage,
  VerifyHealthCardPage,
  VerifyHealthCardSuccessPage,
  VerifyDriverLicenceCardPage,
  VerifySuccessPage,
  VerifyVoluntaryIdCardPage,
  ReVerifyPeiIdSetupPage,
  ReVerifyHealthCardPage,
  ReVerifyHealthCardSuccessPage,
  ReVerifyDriverLicenceCardPage,
  ReVerifyVoluntaryIdCardPage,
  SignInOTPPage,
  IdentityVerificationPage,
  DriverLicenceCardProfileInformationPage,
  VoluntaryIdCardProfileInformationPage,
  HealthCardProfileInformationPage,
  ProfileInformationPage,
  LogoutPage,
  SetupAuthenticatorAppPage,
  AddAuthenticatorAppPage,
  AddAuthenticatorCodePage,
} from '../views'

import { useContext, updateProfileState } from '../Context'
import { addSessionStorage, removeSessionStorage } from '../utils'
import { getQuerySearch } from '../services'

import ROUTES from '../routes/common/constants'

// Create account flow
const TermsOfServiceRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.CreatePeiIdRoute)
  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }
  return <TermsOfServicePage successFunc={onSuccess} goBackLink={goBackAction} />
}

const CreateIdEmailRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.OTPRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.TermsOfServiceRoute)
  }
  return <CreateIdEmailPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const AfterCreateIdEmailRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.CreatePeiIdRoute)
  }
  return <OTPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ apiType: 'createAccount' }} />
}

const AccountReadyRouter = () => {
  const {
    state: {
      config: { base_url },
    },
  } = useContext()
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }

  return (
    <AccountReadyPage
      goBackLink={goBackAction}
      otherRoutes={{
        skipClick: () => {
          window.location.href = `${base_url}/final-redirect`
        },
        nextClick: () => {
          history.push(ROUTES.VerifyPeiIdRoute)
        },
      }}
    />
  )
}

const VerifyIdRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.AccountReadyRoute)
  }

  return (
    <VerifyIdPage
      goBackLink={goBackAction}
      otherRoutes={{
        nextClick: () => {
          history.push(ROUTES.VerifyHealthCardRoute, { from: 'verifyId' })
        },
      }}
    />
  )
}

// Choose 2FA

const Choose2FARouter = () => {
	const history = useHistory();
	const goBackAction = () => {
		history.push(ROUTES.OTPRoute);
	};

  return (
    <Choose2FAPage
      goBackLink={goBackAction}
      otherRoutes={{
        authClick: () => {
          history.push(ROUTES.SetupAuthenticatorAppRoute)
        },
        smsClick: () => {
          history.push(ROUTES.AddSMSAuthRoute)
        },
        emailClick: () => {
          history.push(ROUTES.AddEmailAuthRoute)
        },
      }}
    />
  )
}

const AddEmailAuthRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.AccountReadyRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  return <AddEmailAuthPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddSMSAuthRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.SMSOTPRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  return <AddSMSAuthPage successFunc={onSuccess} otherRoutes={{ apiType: 'post' }} goBackLink={goBackAction} />
}

const AfterSMSOTPRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.AddSMSAuthRoute)
  }
  const onSuccess = () => history.push(ROUTES.AccountReadyRoute)
  return <SMSOTPPage goBackLink={goBackAction} successFunc={onSuccess} otherRoutes={{ apiType: 'createAccount' }} />
}

// Log in to MyPEI for the 2nd time (LOA 3)
const SignInRouter = () => {
  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.OTPSignInRoute)
  const goBackAction = () => (window.location.href = pei_portal_link)
  const createAccount = () => history.push(ROUTES.CreatePeiIdRoute)

  return <SignInPage successFunc={onSuccess} otherRoutes={{ apiType: 'createAccount', createAccount }} goBackLink={goBackAction} />
}

const AfterSignInRouter = () => {
  const history = useHistory()
  const {
    state: {
      config: { base_url },
    },
  } = useContext()
  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }
  const onSuccess = () => (window.location.href = `${base_url}/final-redirect`)
  return <SignInOTPPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ apiType: 'post' }} />
}

const ForgotPasswordRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.ForgotPasswordEmailRoute)
  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }

  return <ForgotPasswordPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const AfterForgotPasswordRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.ForgotPasswordRoute)
  }

  return <ForgotPasswordCheckEmailPage goBackLink={goBackAction} />
}

const ResetPasswordRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.ResetPasswordSuccessRoute)
  return <ResetPasswordPage successFunc={onSuccess} />
}

const AfterResetPasswordRouter = () => {
	const history = useHistory();
	const onSuccess = () => history.push(ROUTES.SigninRoute);
	return <ResetPasswordSuccessPage successFunc={onSuccess} />;
};

// Verification Flow

// HC Verify
const VerifyPeiIdSetupRouter = () => {
  const {
    state: {
      config: { pei_portal_link, manage_pei_url },
      previous,
    },
  } = useContext()
  const history = useHistory()

  const goBackAction = () => {
    window.location.href = previous ? manage_pei_url : pei_portal_link
  }
  const onSuccess = () => {
    history.push(ROUTES.VerifyHealthCardRoute)
  }

  return <VerifyPeiIdSetupPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const VerifyHealthCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()

  const onSuccess = (param: any) => {
    dispatch(updateProfileState(param))
    addSessionStorage('verification-profile', JSON.stringify(param))

    history.push(ROUTES.VerifyHealthCardSuccessRoute)
  }

  const goBackAction = () => {
    history.push(ROUTES.VerifyPeiIdSetupRoute)
  }

  return <VerifyHealthCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const VerifyHealthCardSuccessRouter = () => {
  const history = useHistory()
  const goBackAction = () => history.push(ROUTES.IdentityVerificationRoute)

  return (
    <VerifyHealthCardSuccessPage
      otherRoutes={{
        voluntaryIdVerification: () => {
          history.push(ROUTES.VerifyVoluntaryIdCardRoute)
        },
        drivingLicenseVerification: () => {
          history.push(ROUTES.VerifyDriverLicenceCardRoute)
        },
      }}
      goBackLink={goBackAction}
    />
  )
}

const VerifyDriverLicenceCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()

  const onSuccess = (param: any) => {
    dispatch(updateProfileState(param))
    removeSessionStorage('verification-profile')

    history.push(ROUTES.VerifySuccessRoute)
  }

  const goBackAction = () => {
    history.push(ROUTES.VerifyHealthCardSuccessRoute)
  }

  return <VerifyDriverLicenceCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const VerifyVoluntaryIdCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()

  const onSuccess = (param: any) => {
    dispatch(updateProfileState(param))
    removeSessionStorage('verification-profile')

    history.push(ROUTES.VerifySuccessRoute)
  }

  const goBackAction = () => {
    history.push(ROUTES.VerifyHealthCardSuccessRoute)
  }

  return <VerifyVoluntaryIdCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const VerifySuccessRouter = () => {
  const {
    state: {
      config: { base_url },
    },
  } = useContext()

  const onSuccess = () => (window.location.href = `${base_url}/final-redirect`)

  return <VerifySuccessPage successFunc={onSuccess} />
}

// Re Verification Flow

const ReVerifyIdRouter = () => {
  const history = useHistory()
  let location: any = useLocation()

  const goBackAction = () => {
    if (location?.state?.page === 'DRIVER_LICENSE_CARD_VERIFIED') {
      return history.push(ROUTES.IdentityVerificationDriverLicenceCardLevel3Route)
    } else if (location?.state?.page === 'VOLUNTARY_CARD_VERIFIED') {
      return history.push(ROUTES.IdentityVerificationVoluntaryIdCardLevel3Route)
    } else if (location?.state?.page === 'HEALTH_CARD_VERIFIED') {
      return history.push(ROUTES.IdentityVerificationHealthCardLevel3Route)
    } else {
      return history.push(ROUTES.IdentityVerificationRoute)
    }
  }

  const onSuccess = () => history.push(ROUTES.ReVerifyHealthCardRoute, { from: location?.state?.from })
  return <ReVerifyPeiIdSetupPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const ReVerifyHealthCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()
  let location: any = useLocation()

  const onSuccess = (param: any) => {
    dispatch(updateProfileState(param))
    addSessionStorage('verification-profile', JSON.stringify(param))

    history.push(ROUTES.ReVerifyHealthCardSuccessRoute, { from: location?.state?.from })
  }

  const goBackAction = () => {
    history.push(ROUTES.ReVerifyIdRoute)
  }

  return <ReVerifyHealthCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const ReVerifyHealthCardSuccessRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()
  let location: any = useLocation()

  const goBackAction = () => {
    removeSessionStorage('verification-profile')
    dispatch(updateProfileState({}))
    history.push(ROUTES.IdentityVerificationRoute)
  }

  return (
    <ReVerifyHealthCardSuccessPage
      otherRoutes={{
        voluntaryIdVerification: () => {
          history.push(ROUTES.ReVerifyVoluntaryIdCardRoute, { from: location?.state?.from })
        },
        drivingLicenseVerification: () => {
          history.push(ROUTES.ReVerifyDriverLicenceCardRoute, { from: location?.state?.from })
        },
      }}
      goBackLink={goBackAction}
    />
  )
}

const ReVerifyDriverLicenceCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()
  let location: any = useLocation()

  const onSuccess = () => {
    dispatch(updateProfileState({}))
    removeSessionStorage('verification-profile')

    history.push(ROUTES.ReVerifyVerifySuccessRoute, { from: location?.state?.from })
  }

  const goBackAction = () => {
    history.push(ROUTES.ReVerifyHealthCardSuccessRoute)
  }

  return <ReVerifyDriverLicenceCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const ReVerifyVoluntaryIdCardRouter = () => {
  const { dispatch } = useContext()
  const history = useHistory()
  let location: any = useLocation()

  const onSuccess = () => {
    dispatch(updateProfileState({}))
    removeSessionStorage('verification-profile')

    history.push(ROUTES.ReVerifyVerifySuccessRoute, { from: location?.state?.from })
  }

  const goBackAction = () => {
    history.push(ROUTES.ReVerifyHealthCardSuccessRoute)
  }

  return <ReVerifyVoluntaryIdCardPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const ReVerifyVerifySuccessRouter = () => {
  const {
    state: {
      config: { base_url },
    },
  } = useContext()

  const onSuccess = () => {
    return (window.location.href = `${base_url}/final-redirect`)
  }

  return <VerifySuccessPage successFunc={onSuccess} />
}

// Manage Verifcation

const IdentityVerificationRouter = () => {
  const {
    state: {
      config: { manage_pei_url },
    },
  } = useContext()
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.VerifyPeiIdSetupRoute)
  const goBackAction = () => (window.location.href = manage_pei_url)

  return (
    <IdentityVerificationPage
      successFunc={onSuccess}
      otherRoutes={{
        voluntaryIdVerification: () => {
          history.push(ROUTES.IdentityVerificationVoluntaryIdCardLevel3Route)
        },
        drivingLicenseVerification: () => {
          history.push(ROUTES.IdentityVerificationDriverLicenceCardLevel3Route)
        },
        healthcardVerification: () => {
          history.push(ROUTES.IdentityVerificationHealthCardLevel3Route)
        },
        reVerification: () => {
          history.push(ROUTES.ReVerifyIdRoute)
        },
      }}
      goBackLink={goBackAction}
    />
  )
}

const IdentityVerificationDriverLicenceCardLevel3Router = () => {
  const history = useHistory()

  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'DRIVER_LICENSE_CARD_VERIFIED' })
  return <DriverLicenceCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const IdentityVerificationVoluntaryIdCardLevel3Router = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'VOLUNTARY_CARD_VERIFIED' })
  return <VoluntaryIdCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const IdentityVerificationHealthCardLevel3Router = () => {
  const history = useHistory()

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'HEALTH_CARD_VERIFIED' })
  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  return <HealthCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const ProfileInformationRouter = () => {
  const {
    state: {
      config: { manage_pei_url },
    },
  } = useContext()

  const goBackAction = () => (window.location.href = manage_pei_url)

  return <ProfileInformationPage goBackLink={goBackAction} />
}

const LogoutRouter = () => {
  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()
  const loggingOut = () => {
    window.history.replaceState('state', '')
    window.localStorage.clear()
    window.sessionStorage.clear()
    // redirect
    const getErrorParam = getQuerySearch()
    const errorParam = JSON.stringify(getErrorParam) !== '{}' ? `&error=${getErrorParam.error}` : ''
    window.location.assign(pei_portal_link + '?logout=true' + errorParam)
  }

  return <LogoutPage otherRoutes={{ loggingOut: loggingOut }} />
}

// Authenticator App Routers

const SetupAuthenticatorAppRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }

  const onSuccess = () => history.push(ROUTES.AddAuthenticatorAppRoute)

  return <SetupAuthenticatorAppPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddAuthenticatorAppRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.SetupAuthenticatorAppRoute)
  }

  const onSuccess = () => history.push(ROUTES.AddAuthenticatorCodeRoute)

  return <AddAuthenticatorAppPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddAuthenticatorCodeRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.AddAuthenticatorAppRoute)
  }

  const onSuccess = () => history.push(ROUTES.AccountReadyRoute)

  return <AddAuthenticatorCodePage goBackLink={goBackAction} successFunc={onSuccess} />
}

export {
  AccountReadyRouter,
  AddSMSAuthRouter,
  AddEmailAuthRouter,
  AfterCreateIdEmailRouter,
  AfterForgotPasswordRouter,
  AfterResetPasswordRouter,
  AfterSignInRouter,
  AfterSMSOTPRouter,
  Choose2FARouter,
  CreateIdEmailRouter,
  ForgotPasswordRouter,
  ResetPasswordRouter,
  SignInRouter,
  TermsOfServiceRouter,
  VerifyIdRouter,
  VerifyPeiIdSetupRouter,
  VerifyHealthCardRouter,
  VerifyHealthCardSuccessRouter,
  VerifyDriverLicenceCardRouter,
  VerifySuccessRouter,
  VerifyVoluntaryIdCardRouter,
  ReVerifyIdRouter,
  ReVerifyHealthCardRouter,
  ReVerifyHealthCardSuccessRouter,
  ReVerifyDriverLicenceCardRouter,
  ReVerifyVoluntaryIdCardRouter,
  ReVerifyVerifySuccessRouter,
  IdentityVerificationRouter,
  IdentityVerificationHealthCardLevel3Router,
  IdentityVerificationDriverLicenceCardLevel3Router,
  IdentityVerificationVoluntaryIdCardLevel3Router,
  ProfileInformationRouter,
  LogoutRouter,
  SetupAuthenticatorAppRouter,
  AddAuthenticatorAppRouter,
  AddAuthenticatorCodeRouter,
}
