import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import i18n from './i18n'

import { useContext } from './Context'
import { updateConfig, updateError, updateToken, updatePrevious } from './Context'

import { getConfig, getHash } from './services'

import AppRoutes from './routes'
import ROUTES from './routes/common/constants'

import './styles/App.css'
import './styles/App.scss'
import Providers from './providers'

function App() {
  const [ready, setReady] = useState(false)
  const { state, dispatch } = useContext()

  useEffect(() => {
    const getConfigSetup = async () => {
      const portrait = window.matchMedia('(orientation: portrait)')
      const landscape = window.matchMedia('(orientation: landscape)')
      const configData = (await getConfig())?.data

      if (configData.whitelabel) {
        var headID = document.getElementsByTagName('head')[0]
        var link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        headID.appendChild(link)
        link.href = configData.whitelabel
      }
      dispatch(updateConfig(configData))
      dispatch(updateToken(getHash('token')))
      dispatch(updatePrevious(getHash('previous')))
      Modal.setAppElement('#root')
      portrait.addEventListener('change', function (e) {
        if (e.matches) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
      landscape.addEventListener('change', function (e) {
        if (e.matches) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
      await i18n.init()
      setReady(true)
    }
    getConfigSetup()
    window.addEventListener('resize', function () {
      calcViewportUnits()
    })
    calcViewportUnits()
    // eslint-disable-next-line
  }, [])

  const calcViewportUnits = () => document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')

  // when error timeout or 401, redirect to the PEI portal page
  useEffect(() => {
    if (state.userInfo.timeout) {
      dispatch(updateError(false))
      // redirect
      window.location.assign(`${ROUTES.LogoutRoute}?error=session_expired`)
    }
    // eslint-disable-next-line
  }, [state.userInfo.timeout, state.config.pei_portal_link])

  if (!ready) {
    return null
  }

  return (
    <>
      <Providers>
        <AppRoutes />
      </Providers>
    </>
  )
}

export default App
