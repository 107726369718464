import React from "react";
import {
	Layout,
	PasscodeForm,
	Text,
	Title,
	IconListItem,
	Button,
	Notification,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { ReactComponent as Phone } from "../../asset/icon_phone_code.svg";
import { PageI } from "../../components/ComponentInterface";

const SMSOTPPage = ({
	goBackLink,
	successFunc,
	otherRoutes: { apiType = "post" } = {},
}: PageI) => {
	return (
		<Layout
			header
			footer
			title={i18n.t("setup-sms-auth")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="add-sms progress-bar"
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={5}
		>
			<Title title={i18n.t("setup-sms-title")} />
			<Text className="lead-text">
				{parse(i18n.t("setup-sms-description"))}
			</Text>
			<IconListItem logo={<Phone />}>
				<Text>
					{parse(i18n.t("setup-sms-description-list-step-2"))}
				</Text>
			</IconListItem>
			<div className="code-form">
				<PasscodeForm
					successFunc={successFunc}
					apiType={apiType}
					twoFaMethod="SMS"
				/>
				<br />
				<Button
					className="inverted"
					text={i18n.t("go-back")}
					onClick={goBackLink}
					dataTestId="Button-secondary"
				/>
			</div>
			<Notification
				text={parse(i18n.t("auth-alert-sms"))}
				className="notice-box"
			/>
		</Layout>
	);
};

export default SMSOTPPage;
