import React from "react";
import { Button, Layout, Text, Title } from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { ReactComponent as CheckIcon } from "../../asset/icon_check.svg";

import { PageI } from "../../components/ComponentInterface";

const AccountReadyPage = ({
	goBackLink,
	otherRoutes: { skipClick, nextClick },
}: PageI) => {
	return (
		<Layout
			header
			footer
			title={i18n.t("account-ready-title")}
			className={"account-ready progress-bar no-back"}
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={6}
		>
			<div className="account-ready-title">
				<CheckIcon />
				<Title title={i18n.t("account-ready-title")} />
			</div>
			<Text className="lead-text">
				{parse(i18n.t("account-ready-description-1"))}
			</Text>
			<Text>{parse(i18n.t("account-ready-description-2"))}</Text>
			{/* <Text>{parse(i18n.t("account-ready-description-3"))}</Text> */}
			<Text className="withLink account-ready-last">
				{parse(
					i18n.t("account-ready-description-3", {
						link: `${window.GLOBAL_PATH}`,
					})
				)}
			</Text>
			<div className="button-container-3">
				<Button
					className="inverted account-ready-skips"
					text={i18n.t("skip-go-myPei")}
					onClick={skipClick}
					dataTestId="Button-secondary"
				/>
				<Button
					text={i18n.t("continue-idv")}
					onClick={nextClick}
					dataTestId="Button-primary"
				/>
			</div>
		</Layout>
	);
};

export default AccountReadyPage;
