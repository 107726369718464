import { useTriggeredUpdateRegister } from "../../../services";
import { updateError } from "../../../Context";
import { InputI, errorI } from "../../ComponentInterface";

const useSubmitApi = () => {
	const { useUpdateRegister: updateRegister, isLoading } =
		useTriggeredUpdateRegister();

	const submitApiCall = async (
		inputs: InputI,
		updatedToken: any,
		successFunc: ((param?: any) => void) | undefined,
		handleApiError: (error: errorI) => void,
		dispatch: (arg0: { type: string; payload: any }) => void,
		terms_accepted: any
	) => {
		const { email, createPassword, firstName, lastName } = inputs;
		const { response, error } = await updateRegister({
			data: {
				first_name: firstName,
				last_name: lastName,
				email,
				password: createPassword,
				recaptcha: updatedToken,
				terms_accepted,
			},
		});

		if (!updatedToken) {
			handleApiError({ error: "error-500-1" });
			return;
		}

		if (response.isSuccess) {
			if (email) {
				localStorage.setItem("userEmail", email);
			}
			successFunc && successFunc();
		} else if (error.isConflictError) {
			handleApiError({
				error: "error-account-create-email-exist",
			});
		} else if (error.isUnauthorizedError || error.isForbiddenError) {
			dispatch(updateError(true));
		} else {
			handleApiError({ error: "error-500-1" });
		}
	};

	return { submitApiCall, isLoading };
};

export default useSubmitApi;
