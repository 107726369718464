import React, { FC } from 'react'

interface Props {
  title: string
  value?: string
  action?: string
  onClick?: () => void
  last?: boolean
}

const ListItem: FC<Props> = ({ title, value, action, onClick, last }) => {
  return (
    <div className={`list-item ${!last ? 'first' : 'last'}`}>
      <div className="description">
        <div className="list-title">{title}</div>
        <div className="list-value">{value}</div>
      </div>
      <div className="list-action" onClick={() => onClick && onClick()}>
        {action}
      </div>
    </div>
  )
}

export default ListItem
