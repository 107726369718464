import axios, { AxiosRequestConfig } from 'axios'
import { getServices } from '../services/ServiceRegistry'

type RestMethods = 'GET' | 'POST' | 'DELETE' | 'PUT'

export async function makeApiCall<T>(method: RestMethods, url: string, options?: AxiosRequestConfig): Promise<T> {
  const { config } = getServices()

  try {
    const response = await axios({
      method,
      url,
      baseURL: config.ws_api_url,
      ...options,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response || error
    } else {
      throw new Error('An unknown error occurred')
    }
  }
}

function getDefaultApiOptions(): AxiosRequestConfig {
  const { storage } = getServices()

  return {
    headers: {
      Authorization: `Bearer ${storage.get('session')}`,
    },
  }
}

export async function makeAuthenticatedApiCall<T>(method: RestMethods, url: string, options?: AxiosRequestConfig): Promise<T> {
  const defaultOptions = getDefaultApiOptions()
  return makeApiCall<T>(method, url, { ...defaultOptions, ...options })
}
