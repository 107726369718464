import { useEffect } from "react";
import { useTriggeredLogout } from '../services';
import { PageI } from '../components/ComponentInterface';

const LogoutPage = ({ otherRoutes: { loggingOut } }: PageI) => {
  const { useLogout: logout } = useTriggeredLogout();
  useEffect(() => {
    const getLogout = async () => {
      await logout();
      return loggingOut();
    };
    getLogout();
    // eslint-disable-next-line
  }, [])
  return null;
}

export default LogoutPage;