import React, {
  useEffect,
  useState
} from 'react';
import {
  Button,
  Modal,
  Notification,
  Spinner,
  Text,
  TextInput
} from '..';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import { useTriggeredUpdateRegistrationVID } from '../../services'
import {
  useContext,
  updateError,
} from '../../Context';
import {
  numberRegex,
  removeDashSpaceSpecialChar,
  setDashesIndex,
  setFullDateInput,
  setSlashIndex,
} from '../../utils'
import {
  InputI,
  InputValidateI,
  errorI,
  VoluntaryCardFormI
} from '../ComponentInterface'

const VoluntaryForm = ({ readOnlyDob, readOnlyVoluntary, readOnlyFirstName, readOnlyLastName, successFunc, isReverify }: VoluntaryCardFormI) => {
  const { useUpdateRegistrationVID: updateRegistrationVID, isLoading } = useTriggeredUpdateRegistrationVID();
  const { state, dispatch } = useContext();
  const [inputs, setInputs] = useState<InputI>({ firstName: '', lastName: '', serial: '', dln: '', expiryDate: '', dob: '' })
  const [validInputs, setValidateInputs] = useState<InputValidateI>({ isValidBtn: false });
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' });
  // modal
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const { userInfo: { profile: { first_name, last_name, voluntary_id, expiry_date_voluntary_id, birthdate, serial_number_voluntary_id } } } = state;
    
    if (isReverify) {
      setInputs({ ...inputs, firstName: first_name, lastName: last_name, voluntary: voluntary_id, expiryDate: expiry_date_voluntary_id, dob: birthdate, serial: serial_number_voluntary_id })
      setValidateInputs({ ...validInputs, isValidFirstName: true, isValidLastName: true, isValidVoluntary: Boolean(voluntary_id), isValidExpiryDate: Boolean(expiry_date_voluntary_id), isValidDob: true, isValidSerial: Boolean(serial_number_voluntary_id) })
    } else {
      setInputs({ ...inputs, firstName: first_name, lastName: last_name, dob: birthdate })
      setValidateInputs({ ...validInputs, isValidFirstName: true, isValidLastName: true,  isValidDob: true })
    }

    // eslint-disable-next-line 
  }, [state.userInfo?.profile])

  useEffect(() => {
    setValidateInputs(prevState => ({ ...prevState, isValidBtn: Boolean(validInputs.isValidFirstName) && Boolean(validInputs.isValidLastName) && Boolean(validInputs.isValidVoluntary) && Boolean(validInputs.isValidExpiryDate) && Boolean(validInputs.isValidDob) && Boolean(validInputs.isValidSerial) }))
    // eslint-disable-next-line
  }, [validInputs.isValidFirstName, validInputs.isValidLastName, validInputs.isValidVoluntary, validInputs.isValidExpiryDate, validInputs.isValidDob, validInputs.isValidSerial]);

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      isError: (errorObj.error === 'error-500-2' || errorObj.error === 'error-voluntary-invalid')
    })
  
    // eslint-disable-next-line
  }, [errorObj.error]);

  useEffect(() => {
    if (isLoading) {
      setModalContent('loading-voluntary-card')
    } else {
      setModalContent(prevState => prevState !== 'loading-voluntary-card' ? prevState : '')
    }
  }, [isLoading]);

  useEffect(() => {
    setModalOpen(Boolean(modalContent));
  }, [modalContent])

  const toggleModal = () => setModalOpen(!isModalOpen);

  const infoClick = (e: React.MouseEvent<HTMLElement>, type?: string) => {
    setModalOpen(true)
    switch (type) {
      case 'first_name':
        setModalContent('voluntary-modal-first-name');
        break;
      case 'last_name':
        setModalContent('voluntary-modal-last-name');
        break;
      case 'voluntary_number':
        setModalContent('voluntary-modal-voluntary-number');
        break;
      case 'expiry_date':
        setModalContent('voluntary-modal-expiry-date');
        break;
      case 'serial':
        setModalContent('voluntary-modal-serial');
        break;
      case 'dob':
        setModalContent('voluntary-modal-dob');
        break;
      case 'reverify_voluntary_number':
        setModalContent('voluntary-modal-voluntary-number-reverify');
        break;
      default:
    }
  };

  const onSerialChange = (e: any) => {
    if (!numberRegex(e.target.value)) return;
    setInputs({ ...inputs, serial: e.target.value });
  }

  const onVoluntaryChange = (e: any) => {
    if (!numberRegex(e.target.value)) return;
    setInputs({ ...inputs, voluntary: e.target.value });
  }

  const onExpiryDateChange = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return;
    setFullDateInput(e.target.value, () => { setInputs({ ...inputs, expiryDate: setSlashIndex(e.target.value, [3, 5], 10) }) }, true)
  };

  const onDobChange = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return;
    setFullDateInput(e.target.value, () => { setInputs({ ...inputs, dob: setDashesIndex(e.target.value, [3, 5], 10) }) })
  };

  const submitClick = async () => {
    const { dob, voluntary, expiryDate, firstName, lastName, serial } = inputs;
    const { userInfo: { profile }} = state;

    // Clear Previous Errors 
    setErrorObj({
      error: ''
    })
    
    // submitClicked(true)
    const data: any = {
      first_name: firstName,
      last_name: lastName,
      expiry_date: expiryDate,
      birthdate: dob,
      voluntary_id: voluntary,
      serial_number: serial?.replace(/^0+/, '')
    }
    const { response, error } = await updateRegistrationVID({
      data
    });
    if (response.isSuccessValidate) { successFunc && successFunc({...profile, ...data}); }
    else if (error.isTooManyAttemptError) { setModalContent('error-too-many-attempts-vi') }
    else if (error.isConflictError) { setModalContent('card-already-used-voluntary-card') }
    else if (error.isInvalidError || error.isNotFoundError) { setErrorObj({ ...errorObj, error: 'error-voluntary-invalid' }) }
    else if (error.isUnauthorizedError || error.isForbiddenError) { dispatch(updateError(true)); }
    else { setErrorObj({ ...errorObj, error: 'error-500-2' }) }
  };

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className='voluntary-form' >
        {errorObj.isError && <Notification text={parse(i18n.t(errorObj.error, {gpei: state.config?.links?.gpei}))} className='error' />}
        <TextInput type='first_name' onChange={(e) => { setInputs({ ...inputs, firstName: e }) }} value={inputs.firstName || ''} maxLength={150} minLength={1} required label={i18n.t('name')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidFirstName: e }) }} readOnly={readOnlyFirstName} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.firstName)} />

        <TextInput type='last_name' onChange={(e) => { setInputs({ ...inputs, lastName: e }) }} value={inputs.lastName || ''} maxLength={150} minLength={1} required label={i18n.t('last-name')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidLastName: e }) }} readOnly={readOnlyLastName} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.lastName)} />

        <TextInput type={isReverify ? 'reverify_voluntary_number' : 'voluntary_number'} onChange={onVoluntaryChange} value={inputs.voluntary || ''} maxLength={12} minLength={1} required label={i18n.t('voluntary-id-number')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidVoluntary: e }) }} placeholder={'000000'} readOnly={Boolean(readOnlyVoluntary && state.userInfo?.profile?.voluntary_id)} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.voluntary)} />
        
        <TextInput type='expiry_date' onChange={onExpiryDateChange} value={inputs.expiryDate || ''} maxLength={150} minLength={10} required label={i18n.t('expiry-date')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidExpiryDate: e }) }} placeholder={i18n.t('expiry-date-full-placeholder')} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.expiryDate)} />

        <TextInput type='serial' onChange={onSerialChange} value={inputs.serial || ''} maxLength={18} minLength={1} required label={i18n.t('serial')} labelDescription={i18n.t('serial-description')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidSerial: e }) }} placeholder={'000000000000000000'} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.serial)} />

        <TextInput type='dob' onChange={onDobChange} value={inputs.dob || ''} maxLength={150} minLength={10} required label={i18n.t('dob')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidDob: e }) }} placeholder={i18n.t('dob-placeholder')} readOnly={readOnlyDob} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.dob)} />

        <Text>{parse(i18n.t('verify-voluntary-id-validation-description'))}</Text>

        <Button onClick={submitClick} text={i18n.t('submit')} disabled={!validInputs.isValidBtn} isLoading={isLoading} />
      </form>
      <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} canClose={!isLoading} className={isLoading ? 'isLoading' : ''}>
        {isLoading && <Spinner />}
        {modalContent && parse(i18n.t(modalContent, {link: window.GLOBAL_PATH, gpei: state.config?.links?.gpei}))}
        {modalContent === 'card-already-used-voluntary-card' && <Button text={i18n.t('okay')} onClick={toggleModal} />}
        {modalContent === 'error-too-many-attempts-vi' && <Button text={i18n.t('okay')} onClick={() => {window.location.href = `${state.config.base_url}/final-redirect`}} />}
      </Modal>
    </>
  )
}

export default VoluntaryForm
