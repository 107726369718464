import React from 'react'
import { Title, Layout, Text, IconListItem, Button } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';
import { ReactComponent as User } from '../../asset/icon-user-green.svg';
import { ReactComponent as CheckIcon } from '../../asset/icon-green-check.svg';
import { PageI } from '../../components/ComponentInterface';

const ReVerifyHealthCardSuccessPage = ({ goBackLink, otherRoutes: { drivingLicenseVerification, voluntaryIdVerification } }: PageI) => {
  return (
    <Layout header footer title={i18n.t('re-verified-health-card-success-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className='verify-hc-success' logout >
      <Title title={i18n.t('re-verified-health-card-success-title')} />
      <Text>{parse(i18n.t('re-verify-id-setup-description-1'))}</Text>
      <Text>{parse(i18n.t('re-verify-id-setup-description-2'))}</Text>
      <Text>{parse(i18n.t('re-verify-id-setup-description-3'))}</Text>

      <IconListItem logo={<CheckIcon />}>
        <Text>{parse(i18n.t('re-verify-id-setup-description-list-step-1'))}</Text>
      </IconListItem>
      <IconListItem logo={<User />}>
        <Text>{parse(i18n.t('re-verify-id-setup-description-list-step-2'))}</Text>
      </IconListItem>

      <div className="button-container">
        <Button text={i18n.t('verify-id-choose-btn-1')} onClick={drivingLicenseVerification} />
        <Button text={i18n.t('verify-id-choose-btn-2')} onClick={voluntaryIdVerification} />
      </div>
    </Layout>
  )
}

export default ReVerifyHealthCardSuccessPage;