import React from "react";
import {
	AccessPeiForm,
	Button,
	IconListItem,
	Layout,
	Text,
	Title,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { ReactComponent as Pin } from "../../asset/icon-pin.svg";
import { ReactComponent as Age } from "../../asset/icon-16.svg";
import { ReactComponent as Card } from "../../asset/icon-card.svg";
import { ReactComponent as Camera } from "../../asset/icon-camera.svg";
import { ReactComponent as Wifi } from "../../asset/icon-wifi.svg";
import { ReactComponent as Info } from "../../asset/icon-question-grey.svg";
import { PageI } from "../../components/ComponentInterface";

const VerifyIdPage = ({ goBackLink, otherRoutes: { nextClick } }: PageI) => {
	return (
		<Layout
			header
			footer
			title={i18n.t("verify-id-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="verify-id"
			logout
		>
			<Title title={i18n.t("verify-id-title")} />
			<Text>{parse(i18n.t("verify-id-description"))}</Text>
			<Text>{parse(i18n.t("verify-id-list-title"))}</Text>
			<IconListItem logo={<Pin />}>
				<Text>{parse(i18n.t("verify-id-list-step-1"))}</Text>
			</IconListItem>
			<IconListItem logo={<Age />}>
				<Text>{parse(i18n.t("verify-id-list-step-2"))}</Text>
			</IconListItem>
			<IconListItem logo={<Card />}>
				<Text className="withLink">
					{parse(i18n.t("verify-id-list-step-3"))}
				</Text>
			</IconListItem>
			<IconListItem logo={<Camera />}>
				<Text>{parse(i18n.t("verify-id-list-step-4"))}</Text>
			</IconListItem>
			<IconListItem logo={<Wifi />}>
				<Text>{parse(i18n.t("verify-id-list-step-5"))}</Text>
			</IconListItem>
			<IconListItem logo={<Info />}>
				<Text className="withLink">
					{parse(i18n.t("verify-id-list-step-6"))}
				</Text>
			</IconListItem>
			<Button text={i18n.t("continue")} onClick={nextClick} />
			{/* TO DO: add successFunc / route needed for Access PEI Form */}
			<AccessPeiForm />
		</Layout>
	);
};

export default VerifyIdPage;
