import React from "react";
import { PasswordInput, Button } from "../";
import i18n from '../../i18n';
import parse from 'html-react-parser';

import { PasswordUpdateFormI } from '../ComponentInterface';

const PasswordUpdateForm = ({ profileData, onInputChange, setValidateInputs, errorPassword, errorConfirmPassword, updatePassword, isValidPasswordBtn, isLoading, toggleUpdatePasswordForm, validInputs, handleOnBlurEvent }: PasswordUpdateFormI) => {
  return (
    <>
      <PasswordInput name="currentPassword" minLength={10} onChange={onInputChange} value={profileData?.currentPassword || ""} label={i18n.t('profile-information-current-password')} required isFocused={Boolean(profileData?.currentPassword)} isError={errorPassword} onValidate={e=>{setValidateInputs({...validInputs, isValidPassword: e}) }} />
      <PasswordInput name="newPassword" minLength={10} onChange={onInputChange} value={profileData?.newPassword || ""} label={(i18n.t('profile-information-create-new-password'))} onValidate={e=>{setValidateInputs({...validInputs, isValidNewPassword: e}) }} description={parse(i18n.t('password-creation-description'))} required isFocused={Boolean(profileData?.newPassword)} isError={errorConfirmPassword} handleOnBlurEvent={handleOnBlurEvent} />
      <PasswordInput name="confirmPassword" minLength={10} onChange={onInputChange} value={profileData?.confirmPassword || ""} label={i18n.t('profile-information-confirm-new-password')} onValidate={e=>{setValidateInputs({...validInputs, isValidConfirmPassword: e}) }} required isFocused={Boolean(profileData?.confirmPassword)} isError={errorConfirmPassword} handleOnBlurEvent={handleOnBlurEvent} />

      <Button className={"cancel-btn"} onClick={toggleUpdatePasswordForm} text={i18n.t('cancel')} />
      <Button onClick={updatePassword} text={i18n.t('save')} disabled={!isValidPasswordBtn} isLoading={isLoading} />
    </>
  )
}

export default PasswordUpdateForm;