import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import IconButton from "../buttons/IconButton";
import { ReactComponent as Password } from "../../asset/icon-password.svg";
import { ReactComponent as PasswordClose } from "../../asset/icon-password-close.svg";
import { validatePassword } from "../validate";
import { PasswordInputI } from "../ComponentInterface";

const PasswordInput = ({
	value,
	onChange,
	optional,
	label,
	maxLength,
	minLength,
	onValidate,
	removeIcon,
	description,
	required,
	isError,
	name,
	isFocused,
	handleOnBlurEvent,
	dataTestId,
}: PasswordInputI) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(true);

	useEffect(() => {
		if (removeIcon) setIsPasswordVisible(true);
	}, [removeIcon]);

	const passwordItem = {
		type: "password",
	};

	const passwordIconClick = () => setIsPasswordVisible(!isPasswordVisible);

	const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(e);
		if (onValidate)
			onValidate(validatePassword(e, maxLength || 150, minLength || 0));
	};

	return (
		<div
			className={`textbox-form password-input ${
				removeIcon ? "remove-icon" : ""
			}`}
		>
			<label
				htmlFor={label || "password"}
				className={`${!label ? "visuallyhidden" : ""}`}
			>
				{label || "password"}{" "}
				{optional && <span>{`${i18n.t("optional")}`}</span>}{" "}
				{required && " *"}
			</label>
			<div
				className={`textbox-form-input textbox-form-input-password ${
					isError ? "error" : ""
				} ${isFocused && !isError ? "active-border" : ""}`}
			>
				<input
					onChange={onPasswordChange}
					value={value}
					{...(isPasswordVisible && passwordItem)}
					maxLength={maxLength}
					minLength={minLength}
					formNoValidate
					name={name}
					onBlur={handleOnBlurEvent}
					data-testid={dataTestId}
				/>
				<IconButton
					onClick={passwordIconClick}
					dataTestId={
						isPasswordVisible ? "icon_eye" : "icon_eye-visible"
					}
					icon={!isPasswordVisible ? <Password /> : <PasswordClose />}
				/>
			</div>
			{description && description}
		</div>
	);
};

export default PasswordInput;
