import React, { useEffect, useState } from 'react'
import { Title, Layout, Text, HealthForm, Notification } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';

import { PageI, errorI } from '../../components/ComponentInterface';
import { useTriggeredProfile } from '../../services';
import { useContext, updateProfileState, updateError } from '../../Context';

const ReVerifyHealthCardPage = ({ goBackLink, successFunc }: PageI) => {
  const { useProfile: profile } = useTriggeredProfile();
  const { state: { userInfo }, dispatch } = useContext();
  const [errorObj, setErrorObj] = useState<errorI>({ error: '', errorNotification: false });

  const error500 = () => (setErrorObj({
    ...errorObj,
    error: 'error-500-1',
    errorNotification: true
  }));

  useEffect(() => { 
    // Profile Api call
    const getProfile = async () => {
      try {  
        
        // If Profile not available then get profile 
        if ((!userInfo || !userInfo?.profile || (Object.keys(userInfo?.profile).length === 0)) && !errorObj.error) {
          const { response, error } = await profile();
          if (response.isSuccess) {
            dispatch(updateProfileState(response.data));
          } else if (error.isUnauthorizedError) { 
            dispatch(updateError(true)) 
          } else if (error.isInternalServerError) { 
            setErrorObj({
              ...errorObj,
              error: 'error-setup-500',
              errorNotification: true
            });
          } else error500();
        }
      } catch (e) {
        console.log('e', e);
      }
    }
    
    getProfile();

    // eslint-disable-next-line
  }, [userInfo, errorObj]);

  return (
    <Layout header footer title={i18n.t('verify-health-card-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className='verify-hc-page' logout >
      <Title title={i18n.t('verify-health-card-title')} />
      <Text>{i18n.t('verify-health-card-description')}</Text>

      <div className='error-container'>
        {errorObj.errorNotification && <Notification text={parse(i18n.t(errorObj.error))} className='error' />}
      </div>

      <HealthForm 
        successFunc={successFunc}
        readOnlyHn={true}
        readOnlyDob={true}
        isReverify={true}
      />
    </Layout>
  )
}

export default ReVerifyHealthCardPage;