import React from "react";
import {
	Layout,
	Text,
	Title,
	IconListItem,
	AuthenticatorCodeForm,
	Notification,
	LinkButton,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { PageI } from "../../components/ComponentInterface";
import { ReactComponent as PhoneIcon } from "../../asset/icon-phone-green.svg";
import { ReactComponent as ShowMeIcon } from "../../asset/icon-show-me.svg";

const AddAuthenticatorCodePage = ({ goBackLink, successFunc }: PageI) => {
	return (
		<Layout
			header
			footer
			title={i18n.t("setup-authenticator-app-otp-title")}
			className="authenticator-app-flow progress-bar"
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={5}
		>
			<Title title={i18n.t("setup-authenticator-app-otp-title")} />
			<Text className="subtitle" dataTestId="body-description">
				{parse(i18n.t("setup-authenticator-app-otp-subtitle"))}
			</Text>
			<IconListItem logo={<PhoneIcon />} dataTestId="step4">
				<Text>
					{parse(i18n.t("setup-authenticator-app-otp-page-step-4"))}
				</Text>
			</IconListItem>
			<div className="links-container">
				<IconListItem logo={<ShowMeIcon />} dataTestId="Show-how">
					{/* TODO UPDATE THE REDIRECT URL TO SHOW PAGE */}
					<LinkButton
						text={i18n.t("setup-authenticator-app-show-me-link")}
						onClick={() => {}}
					/>
				</IconListItem>
			</div>
			<AuthenticatorCodeForm
				successFunc={successFunc}
				goBackLink={goBackLink}
			/>

			<Notification
				text={parse(i18n.t("setup-authenticator-app-otp-go-info-bar"))}
				className="notice-box"
				dataTestId="banner-warning"
			/>
		</Layout>
	);
};

export default AddAuthenticatorCodePage;
