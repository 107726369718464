import React, { useEffect, useState } from 'react'
import { Title, Text, Layout, TextInput, Button, Notification, Modal, PasswordUpdateForm } from '../components'
import i18n from '../i18n'
import parse from 'html-react-parser'
import { useTriggeredProfile, useTriggeredProfileUpdateChangePassword, useTriggeredProfileUpdateName } from '../services'
import { useContext, updateProfileState, updateError } from '../Context'

import { PageI, ProfileDataI, errorI, InputValidateI } from '../components/ComponentInterface'
import { ReactComponent as GreenCheckmark } from '../asset/icon-dark-green-check.svg'
import { convertUTCtoLocalTimezone } from '../utils'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import ROUTES from '../routes/common/constants'
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(fr)

const timeAgo = new TimeAgo(['en-US', 'fr'])

const ProfileInformationPage = ({ goBackLink }: PageI) => {
  const { useProfile: profile, isLoading: profileLoading } = useTriggeredProfile()
  const { useUpdateProfileChangePassword: updateProfileChangePassword, isLoading: changePasswordLoading } = useTriggeredProfileUpdateChangePassword()
  const { useUpdateProfileUpdateName: updateProfileName, isLoading: updateProfileLoading } = useTriggeredProfileUpdateName()
  const {
    state: { userInfo, config },
    dispatch,
  } = useContext()

  // error
  const [errorObj, setErrorObj] = useState<errorI>({ error: '', errorNotification: false, errorPassword: false, errorConfirmPassword: false })

  // inputs
  const [profileData, setProfileData] = useState<ProfileDataI>({})

  const [activateUpdateNameForm, setActivateUpdateNameForm] = useState(false)
  const [activateUpdatePasswordForm, setActivateUpdatePasswordForm] = useState(false)

  const [passwordUpdate, setPasswordUpdated] = useState(false)
  const [infoSaved, setInfoSaved] = useState(false)

  // validate
  const [validInputs, setValidateInputs] = useState<InputValidateI>({ isValidUpdateNameBtn: false, isValidPasswordBtn: false })

  // modal
  const [modalContent, setModalContent] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false)

  const error500 = () =>
    setErrorObj({
      ...errorObj,
      error: 'error-500-1',
    })

  useEffect(() => {
    // Profile Api call
    const getProfile = async () => {
      try {
        // If Profile not available then get profile

        if ((!userInfo || !userInfo?.profile || Object.keys(userInfo?.profile).length === 0) && !errorObj.error) {
          const { response, error } = await profile()

          if (response.isSuccess) {
            setProfileData({
              firstName: response.data.first_name,
              lastName: response.data.last_name,
            })
            dispatch(updateProfileState(response.data))
          } else if (error.isUnauthorizedError) {
            dispatch(updateError(true))
          } else if (error.isInternalServerError) {
            setErrorObj({
              ...errorObj,
              error: 'error-setup-500',
            })
          } else error500()
        }
      } catch (e) {
        console.log('e', e)
        error500()
      }
    }

    getProfile()
    // eslint-disable-next-line
  }, [userInfo, errorObj])

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      errorPassword: Boolean(errorObj.error === 'error-profile-information-incorrect-password'),
      errorConfirmPassword: Boolean(errorObj.error === 'error-profile-information-password-do-not-match'),
      errorNotification: Boolean(
        errorObj.error === 'error-500-1' ||
          errorObj.error === 'error-500' ||
          errorObj.error === 'error-profile-information-incorrect-password' ||
          errorObj.error === 'error-profile-information-password-do-not-match',
      ),
    })
    // eslint-disable-next-line
  }, [errorObj.error])

  // Valid Name form
  // Valid Password Form
  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdateNameBtn: Boolean(validInputs.isValidFirstName) && Boolean(validInputs.isValidLastName),
      isValidPasswordBtn:
        Boolean(validInputs.isValidPassword) &&
        Boolean(validInputs.isValidNewPassword) &&
        Boolean(validInputs.isValidConfirmPassword) &&
        Boolean(profileData.newPassword === profileData.confirmPassword),
    })
    // eslint-disable-next-line
  }, [
    validInputs.isValidFirstName,
    validInputs.isValidLastName,
    validInputs.isValidNewPassword,
    validInputs.isValidConfirmPassword,
    profileData.confirmPassword,
    profileData.newPassword,
    profileData.currentPassword,
  ])

  // Unsaved Form Modal
  useEffect(() => {
    if (unsavedModalOpen) {
      setModalOpen(true)
      setModalContent('profile-information-unsaved-modal')
    }
  }, [unsavedModalOpen])

  const infoClick = (e: React.MouseEvent<HTMLElement>, type?: string) => {
    setModalOpen(true)
    switch (type) {
      case 'name-loa1':
        setModalContent('profile-information-name-loa1-modal')
        break
      case 'name-loa3':
        setModalContent('profile-information-name-loa3-modal')
        break
      case 'email':
        setModalContent('profile-information-email-modal')
        break

      default:
    }
  }

  const profileInputOnchangeFunc = (e: any) => {
    const updatedProfileData = {
      ...profileData,
      [e.target.name]: e.target.value.slice(0, 150),
    }

    setProfileData(updatedProfileData)
  }

  const handleOnBlurEvent = () => {
    // Show error message when user move focus from confirm Password or New password

    if (profileData.newPassword && profileData.confirmPassword && profileData.newPassword !== profileData.confirmPassword) {
      setErrorObj({
        ...errorObj,
        error: 'error-profile-information-password-do-not-match',
      })
    }
  }

  const toggleModal = () => {
    // When Closing modal revert back to false
    if (isModalOpen) {
      setUnsavedModalOpen(false)
    }

    setModalOpen(!isModalOpen)
  }

  const toggleUpdateNameForm = () => {
    setErrorObj({
      errorNotification: false,
      errorConfirmPassword: false,
      errorPassword: false,
      error: '',
    })

    // Opening password form while name form is open
    if (activateUpdatePasswordForm && !activateUpdateNameForm) {
      setUnsavedModalOpen(true)
    } else {
      setActivateUpdateNameForm(!activateUpdateNameForm)

      // Validate save button
      setValidateInputs({
        ...validInputs,
        isValidFirstName: true,
        isValidLastName: true,
      })

      // Change to default values on cancel
      setProfileData({
        firstName: userInfo?.profile?.first_name,
        lastName: userInfo?.profile?.last_name,
      })
    }
  }

  const toggleUpdatePasswordForm = () => {
    setErrorObj({
      errorNotification: false,
      errorConfirmPassword: false,
      errorPassword: false,
      error: '',
    })

    // Opening Name form while password form is open
    if (activateUpdateNameForm && !activateUpdatePasswordForm) {
      setUnsavedModalOpen(true)
    } else {
      setActivateUpdatePasswordForm(!activateUpdatePasswordForm)
      setProfileData({
        ...profileData,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      })
    }
  }

  const toggleUnsavedModal = () => {
    setUnsavedModalOpen(false)
    setModalOpen(false)
    setModalContent('')

    // Name Form open
    if (activateUpdateNameForm) {
      setActivateUpdatePasswordForm(true)
      setActivateUpdateNameForm(false)
      setProfileData({
        firstName: userInfo?.profile?.first_name,
        lastName: userInfo?.profile?.last_name,
      })
    } else {
      // If password form open
      setActivateUpdatePasswordForm(false)
      setActivateUpdateNameForm(true)
      setProfileData({
        ...profileData,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      })
    }
  }

  // Password updated Text

  const PassUpdatedTextFunc = () => {
    if (passwordUpdate) {
      return i18n.t('updated-1-min-ago')
    }

    return `${i18n.t('updated')} ${userInfo?.profile?.password_date ? timeAgo.format(convertUTCtoLocalTimezone(userInfo.profile.password_date)) : ''}`
  }

  // Password Update Function

  const updatePassword = async () => {
    const { response, error } = await updateProfileChangePassword({ data: { new_password: profileData.newPassword, current_password: profileData.currentPassword } })

    if (response.isSuccess) {
      setPasswordUpdated(true)
      setActivateUpdatePasswordForm(false)
      setInfoSaved(true)

      setProfileData({
        ...profileData,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      })

      setErrorObj({
        errorNotification: false,
        errorConfirmPassword: false,
        errorPassword: false,
        error: '',
      })
    } else if (error.isUnauthorizedError) {
      setErrorObj({
        ...errorObj,
        error: 'error-profile-information-incorrect-password',
      })
      setInfoSaved(false)
    } else if (error.isInvalidError) {
      setErrorObj({
        ...errorObj,
        error: 'error-profile-information-password-do-not-match',
      })
      setInfoSaved(false)
    } else if (error.isInternalServerError) {
      setErrorObj({
        ...errorObj,
        error: 'error-setup-500',
      })
      setInfoSaved(false)
    } else {
      error500()
      setInfoSaved(false)
    }
  }

  // Name Update Function
  const updateName = async () => {
    const { response, error } = await updateProfileName({ data: { first_name: profileData.firstName, last_name: profileData.lastName } })

    if (response.isSuccess) {
      setActivateUpdateNameForm(false)
      setInfoSaved(true)
      setProfileData({
        firstName: response.data.first_name,
        lastName: response.data.last_name,
      })

      setErrorObj({
        ...errorObj,
        errorNotification: false,
        error: '',
      })
      dispatch(updateProfileState(response.data))
    } else if (error.isUnauthorizedError) {
      dispatch(updateError(true))
      setInfoSaved(false)
    } else if (error.isInternalServerError) {
      setErrorObj({
        ...errorObj,
        error: 'error-setup-500',
      })
      setInfoSaved(false)
    } else {
      error500()
      setInfoSaved(false)
    }
  }

  return (
    <Layout
      header
      footer
      backBtnCallback={goBackLink}
      backText={i18n.t('profile-information-back-button')}
      title={i18n.t('profile-information-title')}
      className={'profile-information'}
      logout
    >
      {infoSaved && <Notification icon={<GreenCheckmark />} text={i18n.t('information-saved')} />}

      <Title title={i18n.t('profile-information-title')} />
      <Text className="sub-title">{parse(i18n.t('profile-information-sub-title'))}</Text>

      <>
        <form onSubmit={(e) => e.preventDefault()} autoComplete="off" className="profile-information-form">
          {errorObj.errorNotification && <Notification text={parse(i18n.t(errorObj.error))} className="error" />}

          {/* First and Last Name Section */}

          <div className={`textbox-form-container-2 ${activateUpdateNameForm ? 'activated-name-form' : ''}`}>
            <TextInput
              type={activateUpdateNameForm ? 'name-loa1' : 'name-loa3'}
              name="firstName"
              onChange={profileInputOnchangeFunc}
              value={profileData?.firstName || ''}
              maxLength={150}
              minLength={1}
              label={i18n.t('profile-information-details-label-1')}
              infoClick={infoClick}
              showCtaIcon={activateUpdateNameForm}
              onValidate={(e) => setValidateInputs({ ...validInputs, isValidFirstName: e })}
              readOnly={!activateUpdateNameForm}
              required={activateUpdateNameForm}
              isFocused={Boolean(profileData?.firstName)}
            />

            <TextInput
              type={activateUpdateNameForm ? 'name-loa1' : 'name-loa3'}
              name="lastName"
              onChange={profileInputOnchangeFunc}
              value={profileData?.lastName || ''}
              maxLength={150}
              minLength={1}
              label={i18n.t('profile-information-details-label-2')}
              showCtaIcon={userInfo.profile?.ial_level !== '1' && !profileLoading}
              infoClick={infoClick}
              onValidate={(e) => setValidateInputs({ ...validInputs, isValidLastName: e })}
              editClick={toggleUpdateNameForm}
              ctaBtnText={userInfo.profile?.ial_level === '1' && !activateUpdateNameForm && !profileLoading ? i18n.t('profile-information-edit-btn') : ''}
              readOnly={!activateUpdateNameForm}
              required={activateUpdateNameForm}
              isFocused={Boolean(profileData?.lastName)}
            />

            {activateUpdateNameForm && (
              <div className="btn-group">
                <Button className={'cancel-btn'} onClick={toggleUpdateNameForm} text={i18n.t('cancel')} />
                <Button onClick={updateName} text={i18n.t('save')} disabled={!validInputs.isValidUpdateNameBtn} isLoading={updateProfileLoading} />
              </div>
            )}
          </div>

          <div className="textbox-form-container-3">
            <TextInput
              type="email"
              value={userInfo?.profile?.email || ''}
              label={i18n.t('profile-information-details-label-3')}
              readOnly
              infoClick={infoClick}
              showCtaIcon={true}
            />
          </div>

          {/* Password Update  */}

          <div className="textbox-form-container-3">
            {activateUpdatePasswordForm ? (
              <PasswordUpdateForm
                profileData={profileData}
                onInputChange={profileInputOnchangeFunc}
                setValidateInputs={setValidateInputs}
                errorPassword={errorObj.errorPassword}
                errorConfirmPassword={errorObj.errorConfirmPassword}
                updatePassword={updatePassword}
                isValidPasswordBtn={validInputs.isValidPasswordBtn}
                isLoading={changePasswordLoading}
                toggleUpdatePasswordForm={toggleUpdatePasswordForm}
                validInputs={validInputs}
                handleOnBlurEvent={handleOnBlurEvent}
              />
            ) : (
              <TextInput
                value={PassUpdatedTextFunc()}
                label={i18n.t('profile-information-details-label-4')}
                editClick={toggleUpdatePasswordForm}
                ctaBtnText={i18n.t('profile-information-edit-btn')}
                readOnly
              />
            )}
          </div>

          <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} canClose={!unsavedModalOpen}>
            {modalContent && parse(i18n.t(modalContent, { link: `${ROUTES.IdentityVerificationRoute}`, gpei: config?.links?.gpei }))}
            {unsavedModalOpen && (
              <div className="btn-group-modal">
                <Button className={'cancel-btn'} onClick={toggleModal} text={i18n.t('cancel')} />
                <Button onClick={toggleUnsavedModal} text={i18n.t('continue')} />
              </div>
            )}
          </Modal>
        </form>
      </>
    </Layout>
  )
}

export default ProfileInformationPage
