import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'

import useScrollToTop from '../common/hooks/useScrollToTop'
import ROUTES from '../routes/common/constants'

import LocalStorage from '../services/LocalStorage'

export const ProtectedWalletRoute = ({ component: Component, ...rest }: any) => {
  useScrollToTop()
  const history = useHistory()

  const sessionWallet = LocalStorage.get('session')
  useEffect(() => {
    if (!sessionWallet) {
      history.push(ROUTES.WalletWelcomeRoute)
    }
    // eslint-disable-next-line
  }, [sessionWallet])

  return (
    <Route
      {...rest}
      render={() => {
        return sessionWallet && <Component />
      }}
    />
  )
}
