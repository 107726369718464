import React, {
  useEffect,
  useState
} from 'react';
import {
  Button,
  Modal,
  Notification,
  Spinner,
  Text,
  TextInput
} from '..';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import { useTriggeredUpdateRegistrationHC } from '../../services'
import {
  useContext,
  updateError
} from '../../Context';
import {
  numberRegex,
  removeDashSpaceSpecialChar,
  setDashesIndex,
  setExpiryDateInput,
  setFullDateInput,
  setSlashIndex
} from '../../utils'
import moment from 'moment';
import {
  InputI,
  InputValidateI,
  errorI,
  HealthCardFormI,
} from '../ComponentInterface';

const HealthForm = ({ readOnlyHn, readOnlyDob, successFunc, isReverify, hideDobCta }: HealthCardFormI) => {
  const { useUpdateRegistrationHC: updateRegistrationHc, isLoading } = useTriggeredUpdateRegistrationHC();
  const { state, dispatch } = useContext();
  const [inputs, setInputs] = useState<InputI>({ firstName: '', lastName: '', hn: '', expiryDate: '', dob: '' })
  const [validInputs, setValidateInputs] = useState<InputValidateI>({ isValidBtn: false, isValidFirstName: false, isValidLastName: false, isValidHn: false, isValidExpiryDate: false, isValidDob: false });
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' });
  const [validForm, setValidForm] = useState<boolean>(false)
  // modal
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const { userInfo: { profile: { first_name, last_name, health_card_number, expiry_date_health_card, birthdate } } } = state;

  useEffect(() => {
    if (isReverify) { 
      setInputs({ ...inputs, firstName: first_name, lastName: last_name, hn: health_card_number, expiryDate: expiry_date_health_card, dob: birthdate })
      setValidateInputs({ ...validInputs, isValidFirstName: true, isValidLastName: true, isValidHn: true, isValidExpiryDate: true, isValidDob: true })
    } else {
      setInputs({ ...inputs, firstName: first_name, lastName: last_name })
      setValidateInputs({ ...validInputs, isValidFirstName: true, isValidLastName: true })
    }
    
    // eslint-disable-next-line
  }, [first_name, last_name, health_card_number, expiry_date_health_card, birthdate])

  useEffect(() => {
    setValidForm(
      Boolean(validInputs.isValidFirstName || inputs.firstName === first_name) &&
        Boolean(validInputs.isValidLastName || inputs.lastName === last_name) &&
        Boolean(validInputs.isValidHn || inputs.hn === health_card_number) &&
        Boolean(validInputs.isValidExpiryDate || inputs.expiryDate === expiry_date_health_card) &&
        Boolean(validInputs.isValidDob || inputs.dob === birthdate)
    )
  }, [
    inputs, inputs.firstName, inputs.lastName, inputs.hn, inputs.expiryDate, inputs.dob, validInputs,
    validInputs.isValidFirstName, validInputs.isValidLastName, validInputs.isValidHn, validInputs.isValidExpiryDate, validInputs.isValidDob,
    birthdate, expiry_date_health_card, health_card_number, first_name, last_name
  ])

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      isError: (errorObj.error === 'error-500-2' || errorObj.error === 'error-health-card-invalid')
    })

    // eslint-disable-next-line
  }, [errorObj.error]);

  useEffect(() => {
    if (isLoading) {
      setModalContent('loading-health-card')
    } else {
      setModalContent(prevState => prevState !== 'loading-health-card' ? prevState : '')
    }
  }, [isLoading]);

  useEffect(() => {
    setModalOpen(Boolean(modalContent));
  }, [modalContent])

  useEffect(() => {
    const { expiryDate } = inputs
    if (!expiryDate) {
      setValidateInputs({ ...validInputs, isValidExpiryDate: false })
    }
    // eslint-disable-next-line
  }, [inputs.expiryDate, validInputs.isValidExpiryDate])

  const toggleModal = () => setModalOpen(!isModalOpen);

  const infoClick = (e: React.MouseEvent<HTMLElement>, type?: string) => {
    setModalOpen(true)
    switch (type) {
      case 'first_name':
        setModalContent('health-card-modal-first-name');
        break;
      case 'last_name':
        setModalContent('health-card-modal-last-name');
        break;
      case 'health_number':
        setModalContent('health-card-modal-health-number');
        break;
      case 'expiry_date':
        setModalContent('health-card-modal-expiry-date');
        break;
      case 'dob':
        setModalContent('health-card-modal-dob');
        break;
      case 'reverify_dob':
        setModalContent('health-card-modal-reverify-dob');
        break;
      case 'reverify_health_number':
        setModalContent('health-card-modal-reverify-health-number');
        break;
      default:
    }
  };

  const onHnChange = (e: any) => {
    if (!numberRegex(e.target.value)) return;
    setInputs({ ...inputs, hn: e.target.value });
  }

  const onExpiryDateChange = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return;
    setExpiryDateInput(e.target.value, () => { setInputs({ ...inputs, expiryDate: setSlashIndex(e.target.value, [3, 5], 7) }) })
  };

  const onDobChange = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return;
    setFullDateInput(e.target.value, () => { setInputs({ ...inputs, dob: setDashesIndex(e.target.value, [3, 5], 10) }) })
  };

  const submitClick = async () => {
    const { dob = '', hn, expiryDate = '', firstName, lastName } = inputs;
    const { userInfo: { profile }} = state;

    // Clear Previous Errors 
    setErrorObj({
      error: ''
    })

    // age restriction
    const isCurrentUserAbove18 = (moment().diff(moment(removeDashSpaceSpecialChar(dob), 'YYYYMMDD'), 'years')) >= 18
    // submitClicked(true);
    if (!isCurrentUserAbove18) {
      setModalOpen(true)
      setModalContent('error-age-restriction');
      return;
    }

    const data: any = {
      health_card_number: hn,
      first_name: firstName,
      last_name: lastName,
      expiry_date: expiryDate,
      birthdate: dob
    }

    const { response, error } = await updateRegistrationHc({
      data
    });
    
    if (response.isSuccessValidate) { successFunc && successFunc({...profile, ...data}); }
    else if (error.isTooManyAttemptError) { setModalContent('error-too-many-attempts-hc'); }
    else if (error.isConflictError) { setModalContent('card-already-used-health-card'); }
    else if (error.isInvalidError || error.isNotFoundError) { setErrorObj({ ...errorObj, error: 'error-health-card-invalid' }) }
    else if (error.isUnauthorizedError || error.isForbiddenError) { dispatch(updateError(true)) }
    else { setErrorObj({ ...errorObj, error: 'error-500-2' }) }
  };
 
  return (
    <>
      <form onSubmit={e => e.preventDefault()} className='health-card-form' >
        {errorObj.isError && <Notification text={parse(i18n.t(errorObj.error, {gpei: state.config?.links?.gpei}))} className='error' />}
        <TextInput type='first_name' onChange={(e) => { setInputs({ ...inputs, firstName: e.target.value }) }} value={inputs.firstName || ''} maxLength={150} minLength={1} required label={i18n.t('name')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidFirstName: e }) }} isError={errorObj.isError} isFocused={Boolean(inputs.firstName)} />

        <TextInput type='last_name' onChange={(e) => { setInputs({ ...inputs, lastName: e.target.value }) }} value={inputs.lastName || ''} maxLength={150} minLength={1} required label={i18n.t('last-name')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidLastName: e }) }} isError={errorObj.isError} isFocused={Boolean(inputs.lastName)} />

        <TextInput type={isReverify ? 'reverify_health_number' : 'health_number'} onChange={onHnChange} value={inputs.hn || ''} maxLength={8} minLength={8} required label={i18n.t('health-number')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidHn: e }) }} placeholder={'00000000'} readOnly={readOnlyHn} isError={errorObj.isError} showCtaIcon={true} isFocused={Boolean(inputs.hn)} />
        
        <TextInput type='expiry_date' onChange={onExpiryDateChange} value={inputs.expiryDate || ''} maxLength={150} minLength={7} required label={i18n.t('expiry-date')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidExpiryDate: e }) }} placeholder={i18n.t('expiry-date-placeholder')} isError={errorObj.isError} isFocused={Boolean(inputs.expiryDate)} />

        <TextInput type={isReverify ? 'reverify_dob' : 'dob'} onChange={onDobChange} value={inputs.dob || ''} maxLength={150} minLength={10} required label={i18n.t('dob')} infoClick={infoClick} onValidate={e => { setValidateInputs({ ...validInputs, isValidDob: e }) }} placeholder={i18n.t('dob-placeholder')} readOnly={readOnlyDob} isError={errorObj.isError} showCtaIcon={isReverify} hideCta={hideDobCta} isFocused={Boolean(inputs.dob)} />

        <Text>{parse(i18n.t('verify-health-card-validation-description'))}</Text>

        <Button onClick={submitClick} text={i18n.t('submit')} disabled={!validForm} />
      </form>
      <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} canClose={!isLoading} className={isLoading ? 'isLoading' : ''} >
        {isLoading && <Spinner />}
        {modalContent && (modalContent.includes('modal') ? <div className='modal-info'>{parse(i18n.t(modalContent, {link: window.GLOBAL_PATH, gpei: state.config?.links?.gpei}))}</div> : parse(i18n.t(modalContent, {link: window.GLOBAL_PATH, gpei: state.config?.links?.gpei})))}
        {((modalContent === 'card-already-used-health-card') || (modalContent === 'error-age-restriction')) && <Button text={i18n.t('okay')} onClick={toggleModal} />}
        {(modalContent === 'error-too-many-attempts-hc') && <Button text={i18n.t('okay')} onClick={() => {window.location.href = `${state.config.base_url}/final-redirect`}} />}
      </Modal>
    </>
  )
}

export default HealthForm
