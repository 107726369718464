import React, { useEffect, useState } from "react";
import { Card, Layout, LinkButton, Text, Title } from "../../components";
import i18n from "../../i18n";
import { PageI, errorI } from "../../components/ComponentInterface";
import { ReactComponent as ArrowIcon } from "../../asset/solar_arrow-up-linear.svg";
import { useTriggeredAuthenticators } from "../../services";

const Choose2FAPage = ({
	goBackLink,
	otherRoutes: { authClick, smsClick, emailClick },
}: PageI) => {
	const { useAuthenticators: authenticators } = useTriggeredAuthenticators();
	const [hasSMSMethod, setHasSMSMethod] = useState(false);
	const [hasTOTPMethod, setHasTOTPMethod] = useState(false);
	const [errorObj, setErrorObj] = useState<errorI>({
		error: "",
	});

  const error500 = () =>
    setErrorObj({
      ...errorObj,
      error: 'error-500-1',
    })

  useEffect(() => {
    const getAuthenticators = async () => {
      try {
        if (!errorObj.error) {
          const { response } = await authenticators()

					if (response.isSuccess) {
						const data = response.data;
						const hasSMS = data.some(
							(item: { method: string }) => item.method === "SMS"
						);
						setHasSMSMethod(hasSMS);
						const hasTOTP = data.some(
							(item: { method: string }) => item.method === "TOTP"
						);
						setHasTOTPMethod(hasTOTP);
					} else error500();
				}
			} catch (e) {
				console.log("e", e);
				error500();
			}
		};

    getAuthenticators()

    // eslint-disable-next-line
  }, [])

	return (
		<Layout
			header
			footer
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			title={i18n.t("add-auth-title")}
			className={"choose-2FA progress-bar"}
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={3}
		>
			<Title title={i18n.t("add-auth-title")} />
			<Text className="lead-text">
				{i18n.t("add-auth-description-1")}
			</Text>
			<div className="card-container">
				{hasTOTPMethod && (
					<Card
						icon={<ArrowIcon />}
						title={i18n.t("use-auth-app")}
						description={i18n.t("use-auth-app-description")}
						reminder={i18n.t("use-auth-app-body-reminder")}
						onClick={authClick}
						dataTestId="card-auth-app"
					/>
				)}
				{hasSMSMethod && (
					<Card
						icon={<ArrowIcon />}
						title={i18n.t("use-sms-auth")}
						description={i18n.t("use-sms-auth-description")}
						onClick={smsClick}
						dataTestId="card-auth-text"
					/>
				)}
			</div>
			<Text className="heading">{i18n.t("add-auth-description-2")}</Text>
			<LinkButton
				onClick={emailClick}
				text={i18n.t("use-email-auth")}
				dataTestId="Button-link"
			/>
		</Layout>
	);
};

export default Choose2FAPage
