const ROUTES = {
  LandingPage: '/',
  TermsOfServiceRoute: '/terms-of-service',
  CreatePeiIdRoute: '/create-pei-id',
  OTPRoute: '/otp',
  VerifyPeiIdRoute: '/verify-pei-id',
  SigninRoute: '/sign-in',
  ForgotPasswordRoute: '/forgot-password',
  ForgotPasswordEmailRoute: '/forgot-password-email',
  ResetPasswordRoute: '/reset-password',
  ResetPasswordSuccessRoute: '/reset-password-success',
  OTPSignInRoute: '/otp-sign-in',
  VerifyPeiIdSetupRoute: '/verify-pei-id-setup',
  VerifyHealthCardRoute: '/verify-health-card',
  VerifyHealthCardSuccessRoute: '/verified-health-card-success',
  VerifyDriverLicenceCardRoute: '/verify-driver-licence-card',
  VerifyVoluntaryIdCardRoute: '/verify-voluntary-id-card',
  VerifySuccessRoute: '/verify-success',
  ReVerifyIdRoute: '/re-verify-id',
  ReVerifyIdFromPortalRoute: '/re-verify-id-portal',
  ReVerifyHealthCardRoute: '/re-verify-health-card',
  ReVerifyHealthCardSuccessRoute: '/re-verified-health-card-success',
  ReVerifyDriverLicenceCardRoute: '/re-verify-driver-licence-card',
  ReVerifyVoluntaryIdCardRoute: '/re-verify-voluntary-id-card',
  ReVerifyVerifySuccessRoute: '/re-verify-success',
  ProfileInformationRoute: '/profile-information',
  IdentityVerificationRoute: '/verification-information',
  IdentityVerificationErrorRoute: '/identity-verification-error',
  IdentityVerificationLevel1Route: '/identity-verification-level-1',
  IdentityVerificationLevel3Route: '/identity-verification-level-3',
  IdentityVerificationExpiredRoute: '/identity-verification-expired',
  IdentityVerificationExpiredPortalRoute: '/identity-verification-portal',
  IdentityVerificationHealthCardExpiredRoute: '/identity-verification-health-card-expired',
  IdentityVerificationDriverLicenceCardExpiredRoute: '/identity-verification-driver-licence-card-expired',
  IdentityVerificationVoluntaryIdCardExpiredRoute: '/identity-verification-voluntary-id-card-expired',
  IdentityVerificationHealthCardLevel3Route: '/identity-verification-health-card-level-3',
  IdentityVerificationDriverLicenceCardLevel3Route: '/identity-verification-driver-licence-card-level-3',
  IdentityVerificationVoluntaryIdCardLevel3Route: '/identity-verification-voluntary-id-card-level-3',

  AccountReadyRoute: '/account-ready',
  Choose2FARoute: '/choose-2FA',
  AddEmailAuthRoute: '/add-email-auth',
  AddSMSAuthRoute: '/add-sms-auth',
  SMSOTPRoute: '/sms-otp',
  SetupAuthenticatorAppRoute: '/setup-authenticator-app',
  AddAuthenticatorAppRoute: '/add-authenticator-app',
  AddAuthenticatorCodeRoute: '/add-authenticator-code',
  LogoutRoute: '/logout',
  WalletRoute: '/wallet',
  WalletWelcomeRoute: '/wallet/welcome',
  WalletCallback: '/wallet/auth-callback',
  WalletRSCallback: '/wallet/rs-callback',
  WalletConsentCallback: '/wallet/as-authorize',
  WalletConsentRequest: '/wallet/consent-request',
  WalletConsentPage: '/wallet/consent/:transactionID',
  WalletProfile: '/wallet/profile',
  WalletConsentHistory: '/wallet/consent-history',
  WalletConsentHistoryDetail: '/wallet/detail/:client_id/:client_name/:type',
}

export default ROUTES
