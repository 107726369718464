import React from "react";
import { CardI } from "../ComponentInterface";
// import { Spinner } from "..";
// import { NavLink } from "react-router-dom";
import { ReactComponent as LightbulbIcon } from "../../asset/lightbulb-grey.svg";

const Card = ({
	icon,
	url,
	title,
	description,
	reminder,
	onClick,
	className,
	dataTestId,
}: CardI) => {
	return (
		<button
			className={`card ${className ? className : ""}`}
			tabIndex={0}
			onClick={onClick}
			data-testid={dataTestId}
		>
			<div className="body">
				<h4>{title}</h4>
				<p>{description}</p>
			</div>
			<div className="reminder">
				<div>
					{reminder && (
						<div className="body-reminder">
							<LightbulbIcon />
							<p>{reminder}</p>
						</div>
					)}
				</div>
				<i>{icon}</i>
			</div>
		</button>
	);
};

export default Card;
