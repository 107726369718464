import React from 'react'
import { Title, Layout, Text, IconListItem, Button } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';
import { ReactComponent as User } from '../../asset/icon-user-green.svg';
import { ReactComponent as Health } from '../../asset/icon-health-green.svg';
import { PageI } from '../../components/ComponentInterface';

const ReVerifyPeiIdSetupPage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout header footer title={i18n.t('re-verified-health-card-success-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className='verify-id' logout >
      <Title title={i18n.t('re-verified-health-card-success-title')} />
      <Text>{parse(i18n.t('re-verify-id-setup-description-1'))}</Text>
      <Text>{parse(i18n.t('re-verify-id-setup-description-2'))}</Text>
      <Text>{parse(i18n.t('re-verify-id-setup-description-3'))}</Text>

      <IconListItem logo={<Health />}>
        <Text>{parse(i18n.t('verify-id-description-list-step-1'))}</Text>
      </IconListItem>
      <IconListItem logo={<User />}>
        <Text>{parse(i18n.t('verify-id-description-list-step-2'))}</Text>
      </IconListItem>
      <div className="button-container-2">
        <Button text={i18n.t('next')} onClick={successFunc} />
      </div>
    </Layout>
  )
}

export default ReVerifyPeiIdSetupPage;