import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useContext, updateError } from '../Context'
import ROUTES from '../routes/common/constants'

const scrollTop = () => window.scrollTo(0, 0)

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { state, dispatch } = useContext()
  useEffect(() => {
    if (!state.token) dispatch(updateError(true))
    // eslint-disable-next-line
  }, [state.token])

  return (
    <Route
      {...rest}
      render={() => {
        scrollTop()
        return !state.token ? null : <Component />
      }}
    />
  )
}

export const DefaultRoute = ({ ...rest }) => <Route {...rest} render={() => <Redirect to={{ pathname: ROUTES.LandingPage }} />} />

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={() => {
        scrollTop()
        return <Component />
      }}
    />
  )
}

export default ProtectedRoute
