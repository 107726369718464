import React, { useEffect } from 'react'
import queryString from 'query-string'

import { useHistory } from 'react-router-dom'
import { ConsentRequestCallback } from '../../../../wallet'
import { interpolateRoute } from '../../../../common/utils/interpolateRoute'
import { useWallet } from '../../../../context/wallet'
import { useWalletFlowHelper } from '../../../../context/wallet/WalletFlowHelper'
import { Spinner } from '../../../../components'
import ROUTES from '../../../../routes/common/constants'

const WalletConsentCallback = () => {
  const history = useHistory()

  const {
    ConsentRequestTokenHistory: { add },
  } = useWalletFlowHelper()

  const {
    Consent: { handleCallback },
  } = useWallet()

  const callHandler = async () => {
    const { token, client }: ConsentRequestCallback = queryString.parse(window.location.search) as any

    const transaction = await handleCallback(token, client)

    if (transaction) {
      const {
        transaction_id,
        // eslint-disable-next-line
        client: { identifier },
      } = transaction

      add(transaction)
      history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: transaction_id })}`, { replace: true })
    } else {
      //error page
      history.push(ROUTES.WalletRoute)
    }
  }

  useEffect(() => {
    callHandler()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletConsentCallback
