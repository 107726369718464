import React, { useEffect, useState } from "react";
import {
	Button,
	IconListItem,
	IconInfoItem,
	Layout,
	Text,
	Title,
	LinkButton,
	Modal,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { ReactComponent as Phone } from "../../asset/phone.svg";
import {
	PageI,
	errorI,
	InputI,
	InputValidateI,
} from "../../components/ComponentInterface";
import PhoneInput from "../../components/inputs/PhoneInput";
import { useTriggeredAuthenticatorRegister } from "../../services";
import { useContext, updateProfileState, updateError } from "../../Context";
import { ReactComponent as CheckIcon } from "../../asset/icon-check-green.svg";

const AddSMSAuthPage = ({ successFunc, goBackLink }: PageI) => {
	const {
		state: {
			userInfo: { profile },
		},
		dispatch,
	} = useContext();

	const { useAuthenticatorRegister: authenticatorRegister, isLoading } =
		useTriggeredAuthenticatorRegister();
	const [inputs, setInputs] = useState<InputI>({});
	const [validInputs, setValidateInputs] = useState<InputValidateI>({
		isValidPhoneNumber: false,
	});
	const [isModalOpen, setModalOpen] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number ?? "");
	const [errorObj, setErrorObj] = useState<errorI>({ error: "" });

	const toggleModal = () => setModalOpen(!isModalOpen);

	useEffect(() => {
		setErrorObj({
			...errorObj,
			errorPhoneNumber: errorObj.error === "error-phone-number",
		});
		// eslint-disable-next-line
	}, [errorObj.error]);

	useEffect(() => {
		setValidateInputs({
			...validInputs,
			isValidBtn: Boolean(validInputs.isValidPhoneNumber),
		});
		// eslint-disable-next-line
	}, [validInputs.isValidPhoneNumber]);

	const error500 = () => setErrorObj({ ...errorObj, error: "error-500-1" });

	const submitClick = async () => {
		try {
			const { phoneNumber } = inputs;

			dispatch(
				updateProfileState({ ...profile, phone_number: phoneNumber })
			);

			const { response, error } = await authenticatorRegister({
				data: { method: "SMS", phone_number: phoneNumber },
			});

			if (response.isSuccess) {
				successFunc && successFunc();
			} else if (error?.isUnauthorizedError || error.isForbiddenError) {
				dispatch(updateError(true));
			} else {
				error500();
			}
		} catch (e) {
			console.log("e", e);
			error500();
		}
	};

	return (
		<Layout
			header
			footer
			title={i18n.t("setup-sms-auth")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="add-sms progress-bar"
			stepper
			stepperLabel={i18n.t("account-creation")}
			stepperNumberofSteps={6}
			stepperCurrentStep={4}
		>
			<Title title={i18n.t("setup-sms-title")} />
			<IconListItem logo={<Phone />}>
				<Text>
					{parse(i18n.t("setup-sms-description-list-step-1"))}
				</Text>
			</IconListItem>
			<form onSubmit={(e) => e.preventDefault()} className="phone-form">
				<PhoneInput
					onChange={(e) => {
						setPhoneNumber(e.target.value);
						setInputs({ ...inputs, phoneNumber: e.target.value });
					}}
					value={phoneNumber}
					label={i18n.t("phone")}
					onValidate={(isValid) => {
						setValidateInputs({
							...validInputs,
							isValidPhoneNumber: isValid,
						});
					}}
					required
				/>
			</form>
			<IconInfoItem>
				<LinkButton
					text={i18n.t("learn-about-sms")}
					onClick={toggleModal}
					className="add-sms-learn-more"
					dataTestId="Learn-more"
				/>
			</IconInfoItem>
			<div className="button-container-2">
				<Button
					className="inverted continue"
					text={i18n.t("go-back")}
					onClick={goBackLink}
					dataTestId="Button-secondary"
				/>
				<Button
					text={i18n.t("continue")}
					onClick={submitClick}
					dataTestId="Button-primary"
					className="continue"
					disabled={!validInputs.isValidPhoneNumber}
					isLoading={isLoading}
				/>
			</div>
			<Modal
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
				className="authenticator-modal"
			>
				<h2>{`${i18n.t("add-sms-auth-learn-more-modal-title")}`}</h2>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(i18n.t("add-sms-auth-learn-more-modal-desc-1"))}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(i18n.t("add-sms-auth-learn-more-modal-desc-2"))}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(i18n.t("add-sms-auth-learn-more-modal-desc-3"))}
					</Text>
				</IconListItem>
				<IconListItem logo={<CheckIcon />}>
					<Text>
						{parse(i18n.t("add-sms-auth-learn-more-modal-desc-4"))}
					</Text>
				</IconListItem>
			</Modal>
		</Layout>
	);
};

export default AddSMSAuthPage;
