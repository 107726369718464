import React from "react";
import { IconButtonI } from "../ComponentInterface";

const IconButton = ({ onClick, type, icon, dataTestId }: IconButtonI) => (
	<button
		title="info-btn"
		className="info-btn"
		data-testid={dataTestId}
		onClick={(e) => {
			onClick && onClick(e, type);
		}}
	>
		<i>{icon}</i>
	</button>
);

export default IconButton;
